import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import AdvanceTenderFilters from "./AdvanceTenderFilters";
import { useCTContext } from "../../Context/CTContext";

export default function TenderSearch({
  resultCount,
  filterData,
  advanceFilterData,
}) {
  const { searched } = useCTContext();

  let searchedKeyword = searched;

  const [searchKeyword, setSearchKeyword] = useState(
    searchedKeyword ? searchedKeyword : ""
  );
  const [sortBy, setSortBy] = useState("");

  // const [error, setError] = useState(null);

  useEffect(() => {
    if (searchedKeyword !== "") {
      filterData({
        searchKeyword,
        sortBy,
      });

      highlightText(searchedKeyword);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (event, setStateFunction) => {
    const value = event.target.value;
    setStateFunction(value);
  };

  const highlightText = (text) => {
    if (!text) return;

    const regex = new RegExp(text, "gi");
    const container = document.body;
    const stack = [container];

    container.normalize();

    // Function to remove existing highlighting
    const removeHighlighting = () => {
      const highlightedElements = document.querySelectorAll(".highlight");
      highlightedElements.forEach((element) => {
        const parent = element.parentNode;
        parent.replaceChild(
          document.createTextNode(element.textContent),
          element
        );
        parent.normalize();
      });
    };

    removeHighlighting(); // Remove existing highlighting

    const traverse = () => {
      while (stack.length > 0) {
        const node = stack.pop();

        if (node.nodeType === 3) {
          let match;
          while ((match = regex.exec(node.textContent)) !== null) {
            const range = document.createRange();
            range.setStart(node, match.index);
            range.setEnd(node, match.index + match[0].length);
            const wrapper = document.createElement("span");
            wrapper.className = "highlight";
            range.surroundContents(wrapper);
          }
        } else if (node.nodeType === 1 && node.childNodes) {
          for (let i = node.childNodes.length - 1; i >= 0; i--) {
            stack.push(node.childNodes[i]);
          }
        }
      }
    };

    traverse();
  };

  // if (error) {
  //   return <Box>Error: {error}</Box>;
  // }

  // const sortByField = (field, order) => {
  //   return function (a, b) {
  //     if (order === "asc") {
  //       return a[field].localeCompare(b[field]);
  //     } else {
  //       return b[field].localeCompare(a[field]);
  //     }
  //   };
  // };

  return (
    <>
      <Box>
        <Box
          component="form"
          onSubmit={(e) => {
            e.preventDefault();

            filterData({
              searchKeyword,
              sortBy,
            });
            highlightText(searchKeyword);
          }}
          sx={{
            display: "flex",
            flexDirection: { sm: "row", xs: "column" },
            justifyContent: { md: "space-between", xs: "center" },
            alignItems: "center",
            mb: { xs: 5, md: 3.2 },
          }}
        >
          <TextField
            variant="outlined"
            placeholder="Search By Any Keyword"
            color="secondary"
            size="small"
            sx={{
              width: "100%",
              borderRadius: 2,
              border: "solid transparent",
              ":hover": { border: "solid #8731E8" },
            }}
            value={searchKeyword}
            onChange={(e) => {
              setSearchKeyword(e.target.value);
            }}
          />

          <Button
            sx={{
              ml: { sm: 2, xs: 0 },
              mt: { xs: 2, sm: 0 },
              width: { md: "20%", xs: "45%" },
              py: 1,
              px: 2.3,
              textTransform: "capitalize",
              bgcolor: "#8731E8",
              fontWeight: 600,
              ":hover": {
                bgcolor: "#FFF",
                color: "#8731E8",
              },
            }}
            variant="contained"
            type="submit"
          >
            Search Now
          </Button>
        </Box>

        <AdvanceTenderFilters advanceFilterData={advanceFilterData} />
      </Box>

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "flex-end",
          mt: 2.3,
          mb: 0.5,
        }}
      >
        <Typography sx={{ fontWeight: 600, color: "#666" }}>
          {resultCount + " Results Found "}
          {/* {searchKeyword ? `${searchKeyword}` : "None"} */}
        </Typography>

        <FormControl
          color="secondary"
          size="small"
          sx={{
            ml: 1.4,
            minWidth: 140,
            borderRadius: 2,
            border: "solid transparent",
            ":hover": { border: "solid #8731E8" },
          }}
        >
          <InputLabel>Sort By</InputLabel>
          <Select
            label="Sort By"
            value={sortBy}
            onChange={(e) => handleChange(e, setSortBy)}
          >
            <MenuItem value="">Reset</MenuItem>
            <MenuItem value="asc">Ascending</MenuItem>
            <MenuItem value="desc">Descending</MenuItem>
          </Select>
        </FormControl>
      </Box>
    </>
  );
}
