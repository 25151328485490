import React, { useEffect } from "react";
import { Call, Description, Download, List } from "@mui/icons-material";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import {
  Link,
  useLocation,
  // useLocation
} from "react-router-dom";
import { useCTContext } from "../../Context/CTContext";

export default function TenderOtherDetailsContent({
  Document,
  PQ,
  BOQ,
  Corrigendum,
}) {
  const {
    activeTab,

    fetchDoc,
    fetchCorrigendumDoc,
    fetchPQDoc,
    fetchBOQDoc,
    fetchResultDoc,
    fetchBiddersData,
    fetchCompetitorsData,

    doc,
    docCorrigendum,
    docPQ,
    docBOQ,

    biddersDetails,
    competitorsDetails,
    resultDoc,
  } = useCTContext();
  const location = useLocation();
  const { tenderDetails } = location.state;

  useEffect(() => {
    activeTab !== 1 && fetchDoc(tenderDetails.tcno, tenderDetails.tenderprocid);
    activeTab !== 1 &&
      fetchCorrigendumDoc(tenderDetails.tcno, tenderDetails.tenderprocid);
    activeTab !== 1 &&
      fetchPQDoc(tenderDetails.tcno, tenderDetails.tenderprocid);
    activeTab !== 1 &&
      fetchBOQDoc(tenderDetails.tcno, tenderDetails.tenderprocid);

    activeTab === 1 && fetchResultDoc(tenderDetails.resultid);
    activeTab === 1 && fetchBiddersData(tenderDetails.resultid);
    activeTab === 1 && fetchCompetitorsData(tenderDetails.resultid);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: { sm: "row", xs: "column" },
          justifyContent: "space-between",
          mt: 4,
          "& svg": { color: "#8731E8" },
          ".MuiCard-root": { mt: { xs: 2.3, sm: 0 } },
          ".MuiCardContent-root": {
            maxHeight: 32,
            overflow: "auto",
            py: "0 !important",
            my: 2,
          },
        }}
      >
        <Card>
          <CardHeader
            sx={{ width: "100%" }}
            title={
              <Typography variant="h6">
                <Description sx={{ mr: 0.2 }} />
                Tender Documents
              </Typography>
            }
          />

          <Divider sx={{ height: "1px", bgcolor: "#666" }} variant="middle" />

          <CardContent>
            {doc.document?.length > 0 ? (
              doc.document?.map((document, i) =>
                document?.DocName ? (
                  <>
                    <Divider
                      key={`divider${i}`}
                      sx={{
                        height: "1px",
                        bgcolor: "#ddd",
                        mb: 2,
                        width: "100%",

                        ":first-of-type": {
                          display: "none",
                          mb: 0,
                        },
                      }}
                    />
                    <Box
                      key={i}
                      sx={{ width: "100%" }}
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      {Document === "1" ? (
                        <>
                          <Typography>{document?.DocName}</Typography>
                          <Download
                            sx={{ cursor: "pointer" }}
                            onClick={() => {
                              console.log("Clicked");
                            }}
                          />
                        </>
                      ) : (
                        `Unvailable`
                      )}
                    </Box>
                  </>
                ) : null
              )
            ) : (
              <Box
                sx={{ width: "100%" }}
                display="flex"
                justifyContent="center"
              >
                Not Published
              </Box>
            )}
          </CardContent>
        </Card>

        <Card>
          <CardHeader
            title={
              <Typography variant="h6">
                <Description sx={{ mr: 0.2 }} />
                PQ
              </Typography>
            }
          />

          <Divider sx={{ height: "1px", bgcolor: "#666" }} variant="middle" />

          <CardContent>
            {docPQ.document?.length > 0 ? (
              docPQ.document.map((document, i) =>
                document?.DocName ? (
                  <>
                    <Divider
                      key={`divider${i}`}
                      sx={{
                        height: "1px",
                        bgcolor: "#ddd",
                        mb: 2,
                        width: "100%",

                        ":first-of-type": {
                          display: "none",
                          mb: 0,
                        },
                      }}
                    />

                    <Box
                      key={i}
                      sx={{ width: "100%" }}
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      {PQ === "1" ? (
                        <>
                          <Typography>{document?.DocName}</Typography>
                          <Download sx={{ cursor: "pointer" }} />
                        </>
                      ) : (
                        `Not Published`
                      )}
                    </Box>
                  </>
                ) : null
              )
            ) : (
              <Box
                sx={{ width: "100%" }}
                display="flex"
                justifyContent="center"
              >
                Not Published
              </Box>
            )}
          </CardContent>
        </Card>
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: { sm: "row", xs: "column" },
          justifyContent: "space-between",
          mt: 4,
          "& svg": { color: "#8731E8" },
          ".MuiCard-root": { mt: { xs: 2.3, sm: 0 } },
          ".MuiCardContent-root": {
            maxHeight: 32,
            overflow: "auto",
            py: "0 !important",
            my: 2,
          },
        }}
      >
        <Card>
          <CardHeader
            title={
              <Typography variant="h6">
                <Description sx={{ mr: 0.2 }} />
                Corrigendum
              </Typography>
            }
          />

          <Divider sx={{ height: "1px", bgcolor: "#666" }} variant="middle" />

          <CardContent>
            {docCorrigendum.document?.length > 0 ? (
              docCorrigendum.document.map((document, i) =>
                document?.DocName ? (
                  <>
                    <Divider
                      key={`divider${i}`}
                      sx={{
                        height: "1px",
                        bgcolor: "#ddd",
                        mb: 2,
                        width: "100%",
                        ":first-of-type": {
                          display: "none",
                          mb: 0,
                        },
                      }}
                    />
                    <Box
                      key={i}
                      sx={{ width: "100%" }}
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      {Corrigendum === "1" ? (
                        <>
                          <Typography>{document?.DocName}</Typography>
                          <Download sx={{ cursor: "pointer" }} />
                        </>
                      ) : (
                        "Not Published"
                      )}
                    </Box>
                  </>
                ) : null
              )
            ) : (
              <Box
                sx={{ width: "100%" }}
                display="flex"
                justifyContent="center"
              >
                Not Published
              </Box>
            )}
          </CardContent>
        </Card>

        <Card>
          <CardHeader
            title={
              <Typography variant="h6">
                <Description sx={{ mr: 0.2 }} />
                BOQ
              </Typography>
            }
          />

          <Divider sx={{ height: "1px", bgcolor: "#666" }} variant="middle" />

          <CardContent>
            {docBOQ.document?.length > 0 ? (
              docBOQ.document.map((document, i) =>
                document?.DocName ? (
                  <>
                    <Divider
                      key={`divider${i}`}
                      sx={{
                        height: "1px",
                        bgcolor: "#ddd",
                        mb: 2,
                        width: "100%",

                        ":first-of-type": {
                          display: "none",
                          mb: 0,
                        },
                      }}
                    />

                    <Box
                      key={i}
                      sx={{ width: "100%" }}
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      {BOQ === "1" ? (
                        <>
                          <Typography>{document?.DocName}</Typography>
                          <Download sx={{ cursor: "pointer" }} />
                        </>
                      ) : (
                        "Not Published"
                      )}
                    </Box>
                  </>
                ) : null
              )
            ) : (
              <Box
                sx={{ width: "100%" }}
                display="flex"
                justifyContent="center"
              >
                Not Published
              </Box>
            )}
          </CardContent>
        </Card>
      </Box>

      {activeTab === 1 && (
        <>
          <Box
            sx={{
              mt: 4,
              svg: { color: "#8731E8" },
              ".MuiCardContent-root": {
                maxHeight: 32,
                overflow: "auto",
                py: "0 !important",
                my: 2,
              },
            }}
          >
            <Card>
              <CardHeader
                title={
                  <Typography variant="h6">
                    <Description sx={{ mr: 0.2 }} />
                    Result
                  </Typography>
                }
              />

              <Divider
                sx={{ height: "1px", bgcolor: "#666" }}
                variant="middle"
              />

              <CardContent>
                {resultDoc.document?.length > 0 ? (
                  resultDoc.document.map((document, i) =>
                    document?.DocName ? (
                      <>
                        <Divider
                          key={`divider${i}`}
                          sx={{
                            height: "1px",
                            bgcolor: "#ddd",
                            mb: 2,
                            width: "100%",

                            ":first-of-type": {
                              display: "none",
                              mb: 0,
                            },
                          }}
                        />

                        <Box
                          key={i}
                          sx={{ width: "100%" }}
                          display="flex"
                          justifyContent="space-between"
                          alignItems="center"
                        >
                          <>
                            <Typography>{document?.DocName}</Typography>
                            <Download sx={{ cursor: "pointer" }} />
                          </>
                        </Box>
                      </>
                    ) : null
                  )
                ) : (
                  <Box
                    sx={{ width: "100%" }}
                    display="flex"
                    justifyContent="center"
                  >
                    Not Published
                  </Box>
                )}
              </CardContent>
            </Card>
          </Box>

          <Box
            sx={{
              mt: 4,
              svg: { color: "#8731E8" },
              "thead th": {
                fontSize: 16,
                fontWeight: 600,
                bgcolor: "#c881ff",
              },
              "tbody td": { width: 64 },
              "tbody>tr:nth-of-type(even)": {
                bgcolor: "rgba(135, 49, 232,.14)",
              },
              ".MuiCardContent-root": {
                maxHeight: 365,
                overflow: "auto",
                py: "0 !important",
                mb: 3,
              },
            }}
          >
            <Card>
              <CardHeader
                title={
                  <Typography variant="h6">
                    <List fontSize="large" sx={{ mr: 0.2 }} />
                    List of Bidders
                  </Typography>
                }
              />

              <Divider
                sx={{ height: "1px", bgcolor: "#666" }}
                variant="middle"
              />

              <CardContent>
                <Table stickyHeader>
                  <TableHead>
                    <TableRow>
                      <TableCell>Bidder Name</TableCell>
                      <TableCell>Status/Rank</TableCell>
                      <TableCell>Bidding Amount</TableCell>
                      <TableCell>Variation</TableCell>
                      <TableCell>Contact</TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody sx={{ maxHeight: 150, overflow: "auto" }}>
                    {biddersDetails.bidders?.length > 0 ? (
                      biddersDetails.bidders?.map((bidder, i) => (
                        <TableRow key={bidder?.BidderId + i}>
                          <TableCell>{bidder?.BidderName}</TableCell>
                          <TableCell>
                            {bidder?.BidderRank || "Not Declared"}
                          </TableCell>
                          <TableCell>{bidder?.BidderAmount}</TableCell>
                          <TableCell>{bidder?.Variation}</TableCell>
                          <TableCell>
                            <Link to={`tel:${bidder?.ContactNo}`}>
                              <Call />
                            </Link>
                          </TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell colSpan={5} sx={{ textAlign: "center" }}>
                          No Bidders Other For Now.
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </CardContent>
            </Card>

            <Card sx={{ mt: 3.2 }}>
              <CardHeader
                title={
                  <Typography variant="h6">
                    <List fontSize="large" sx={{ mr: 0.2 }} />
                    List of Competitors
                  </Typography>
                }
              />

              <Divider
                sx={{ height: "1px", bgcolor: "#666" }}
                variant="middle"
              />

              <CardContent>
                <Table stickyHeader>
                  <TableHead>
                    <TableRow>
                      <TableCell>Bidder Name</TableCell>
                      <TableCell>Tenders Participated</TableCell>
                      <TableCell>Tenders Won</TableCell>
                      <TableCell>Contact</TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {competitorsDetails.competitors?.length > 0 ? (
                      competitorsDetails.competitors?.map((competitor, i) => (
                        <TableRow key={competitor?.BidderId + i}>
                          <TableCell>{competitor?.BidderCompanyName}</TableCell>
                          <TableCell>
                            {competitor?.TenderParticipateCount}
                          </TableCell>
                          <TableCell>{competitor?.TenderWonCount}</TableCell>
                          <TableCell>
                            <Link to={`tel:${competitor?.contactNo}`}>
                              <Call />
                            </Link>
                          </TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell colSpan={4} sx={{ textAlign: "center" }}>
                          No Competitors For Now.
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </CardContent>
            </Card>
          </Box>
        </>
      )}
    </>
  );
}
