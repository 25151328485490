import { PlayArrow } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  CardMedia,
  Container,
  Fab,
  Typography,
} from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";

export default function AboutCT() {
  return (
    <Box component={"section"}>
      <Container sx={{ py: 10 }}>
        <Card
          sx={{
            boxShadow: "none",
            display: "flex",
            flexDirection: { md: "row", xs: "column" },
            justifyContent: "space-between",
            "& h5": {
              position: "relative",
              fontWeight: 600,
              "::before": {
                position: "absolute",
                bottom: -7,
                display: "block",
                height: "4px",
                bgcolor: "#8731E8",
                content: '""',
                width: { md: 80, xs: 50 },
              },
            },
            "& span": { color: "#963aff" },
            "& a": { textDecoration: "none", color: "#FFF" },
          }}
        >
          <Box
            sx={{
              position: "relative",
              width: { md: "48%", xs: "100%" },
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              a: { position: "absolute" },
            }}
          >
            <CardMedia
              component={"img"}
              src="/images/AboutUsVideoBG.png"
              sx={{ width: "100%", height: "100%" }}
            />

            <Link to={"https://www.youtube.com/watch?v=2xHQqYRcrx4"}>
              <Fab
                sx={{
                  bgcolor: "#FFF",
                  svg: { color: "#963aff", width: "41px", height: "41px" },
                  boxShadow: `0 0 0 2px #FFF`,
                  "&:before": {
                    position: "absolute",
                    zIndex: -1,
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    borderRadius: "50%",
                    animation:
                      "3s ease-in-out 0s normal none infinite running waves",
                    background:
                      "rgba(255, 255, 255, .5) none repeat scroll 0 0 padding-box",
                    content: '""',
                  },

                  "&:after": {
                    position: "absolute",
                    zIndex: -1,
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    borderRadius: "50%",
                    animation:
                      "2.8s ease-in-out 0s normal none infinite  running waves",
                    background:
                      "rgba(255, 255, 255, .5) none repeat scroll 0 0 padding-box",
                    content: '""',
                  },
                  "::cue": {
                    position: "absolute",
                    // zIndex: -1,
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    borderRadius: "50%",
                    animation:
                      "2.8s ease-in-out 0s normal none infinite  running waves",
                    background:
                      "rgba(0, 0, 0, .9) none repeat scroll 0 0 padding-box",
                    content: '""',
                  },

                  transform: "scale(1.3)",
                  ":hover": { transform: "scale(1.2)" },

                  "@keyframes waves": {
                    "0%": {
                      transform: "scale(1)",
                      opacity: 0.9,
                    },

                    "100%": {
                      transform: "scale(2.3)",
                      opacity: 0,
                    },
                  },
                }}
              >
                <PlayArrow />
              </Fab>
              <span />
            </Link>
          </Box>

          <CardContent
            sx={{
              p: 0,
              pb: "0 !important",
              width: { md: "48%", xs: "100%" },
              mt: { xs: 3.2, md: 0 },
              textAlign: "justify",

              "& h6": {
                mb: 2,
              },
            }}
          >
            <CardHeader
              title={
                <Typography variant="h5" sx={{ fontSize: { xs: 18 } }}>
                  ABOUT <span>CONSTRUCTION TENDERS</span>
                </Typography>
              }
              sx={{ p: 0, mb: 5 }}
            />

            <Typography variant="subtitle1">
              Construction Tenders. Get complete tender details for thousands of
              Construction etenders, Construction tenders by government and
              private organizations published on various tender and eprocurement
              portals worldwide.
            </Typography>

            <Typography variant="subtitle1">
              Constructiontender.in has the most comprehensive coverage
              throughout all regions and govt authorities. so that you do not
              have to refer 1000+ websites or newspapers. We provide one stop
              tender information portal for latest Gov Construction tenders,
              Construction etendering , and eproc Construction tender gov.
            </Typography>

            <Link to={"/contact"}>
              <Button
                variant="contained"
                color="secondary"
                sx={{
                  bgcolor: "#8731E8",
                  textTransform: "capitalize",
                  fontSize: 18,
                }}
              >
                Contact Us
              </Button>
            </Link>
          </CardContent>
        </Card>
      </Container>
    </Box>
  );
}
