import React, { useState } from "react";
import { Outlet } from "react-router-dom";

import Footer from "./OtherComponents/Footer";
import ScrollToTopButton from "./OtherComponents/ScrollToTopButton";
import Header from "./OtherComponents/Header";

export default function Layout() {
  const [loggedIn, setLoggedIn] = useState(true);

  const handleLogout = () => {
    setLoggedIn(false);
  };

  return (
    <>
      <Header loggedIn={loggedIn} handleLogout={handleLogout} />

      <Outlet />

      <ScrollToTopButton />
      <Footer />
    </>
  );
}
