import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Container,
  Typography,
} from "@mui/material";
import { Link } from "react-router-dom";
import { useCTContext } from "../../Context/CTContext";

export default function TendersByCitySection() {
  const { setSearched } = useCTContext();

  return (
    <Box component={"section"} sx={{ bgcolor: "#FFF", py: 12 }}>
      <Container>
        <Box sx={{ textAlign: "center", mb: 7 }}>
          <Typography
            variant="h5"
            sx={{
              fontWeight: 700,
              fontSize: 26,
              "& span": { fontWeight: 600, color: "#333" },
            }}
          >
            <span>TENDERS BY </span> CITY
          </Typography>
          <Typography>Latest Tenders in City.</Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "row",
            flexWrap: "wrap",

            "& a": {
              textDecoration: "none",
              color: "#FFF",
              textTransform: "capitalize",
            },
          }}
        >
          {cities.map((cityData, index) => (
            <Card
              key={index}
              sx={{
                width: { md: "32%", sm: "48%", xs: "100%" },
                maxHeight: 300,
                minHeight: 300,
                borderRadius: 3,
                boxShadow: "none",
                position: "relative",
                mb: { xs: 2.3, sm: 3.2 },

                ":hover img": {
                  transition: "transform 0.5s ease-in-out",
                  transform: "scale(1.1)",
                },

                ":before": {
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  display: "block",
                  content: "''",
                  bgcolor: "#121B22",
                  opacity: 0.5,
                },
              }}
              onClick={(e) => {
                setSearched(cityData.city);
              }}
            >
              <Link
                to={"/tenders"}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                  height: "100%",
                }}
              >
                <CardMedia
                  component={"img"}
                  src={cityData.imageUrl}
                  alt={""}
                  sx={{
                    width: "100%",
                    height: "100%",
                  }}
                />
                <CardContent
                  sx={{
                    position: "absolute",
                    color: "#FFF",
                    h5: { fontWeight: 600 },
                    textAlign: "center",
                    textShadow: "0 5px 20px black",
                  }}
                >
                  <Typography variant="h5">{cityData.city}</Typography>
                  {/* <Typography variant="subtitle1">{`${cityData.tenderCount||"NA"} Tenders`}</Typography> */}
                </CardContent>
              </Link>
            </Card>
          ))}
        </Box>
      </Container>
    </Box>
  );
}

const cities = [
  {
    imageUrl: "/images/ahmedabad.webp",
    // country: "india",
    state: "gujarat",
    city: "ahmedabad",
    tenderCount: 150,
  },
  {
    imageUrl: "/images/mumbai.jpg",
    // country: "india",
    state: "maharashtra",
    city: "mumbai",
    tenderCount: 307,
  },
  {
    imageUrl: "/images/banglore.jpg",
    // country: "india",
    state: "karnataka",
    city: "bangalore",
    tenderCount: 409,
  },
  {
    imageUrl: "/images/hyderabad.webp",
    // country: "india",
    state: "telangana",
    city: "hyderabad",
    tenderCount: 145,
  },
  {
    imageUrl: "/images/udaipur.jpg",
    // country: "india",
    state: "rajasthan",
    city: "udaipur",
    tenderCount: 276,
  },
  {
    imageUrl: "/images/delhi.jpg",
    // country: "india",
    state: "delhi",
    city: "delhi",
    tenderCount: 321,
  },
];
