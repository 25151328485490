import React from "react";
import Poster from "../OtherComponents/Poster";
import { Box } from "@mui/material";
import AboutCT from "../OtherComponents/AboutCT";
import AboutDataCount from "../OtherComponents/AboutDataCount";
import OurTeam from "../OtherComponents/OurTeam";
import PeopleSays from "../OtherComponents/PeopleSays";
import OurPartners from "../OtherComponents/OurPartners";

export default function AboutUs() {
  return (
    <Box sx={{ bgcolor: "#FFF" }}>
      <Poster title="About Us" />

      <AboutCT />

      <AboutDataCount />

      <OurTeam />

      <PeopleSays />

      <OurPartners />
    </Box>
  );
}
