import React, { useEffect } from "react";
import Poster from "../OtherComponents/Poster";
import TenderDetailsPageContent from "../OtherComponents/TenderDetailsPageContent";
import { useLocation } from "react-router-dom";
import { useCTContext } from "../../Context/CTContext";

export default function TenderDetails() {
  const location = useLocation();
  const { tenderDetails } = location.state;
  const { loginRequire, activeTab } = useCTContext();

  useEffect(() => {
    loginRequire();
  });

  return (
    <>
      <Poster
        title={
          (activeTab === 0 && "Tender Details") ||
          (activeTab === 1 && "Tender Result Details")
        }
        content={
          activeTab === 1 ? (
            <>
              TRID:
              <span> {tenderDetails.resultid}</span>
            </>
          ) : (
            <>
              TC Number:<span> {tenderDetails.tcno}</span>
            </>
          )
        }
        posterHeight={284}
      />

      <TenderDetailsPageContent />
    </>
  );
}
