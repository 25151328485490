import React, { createContext, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  getBOQDocListData,
  getBiddersList,
  getCityNames,
  getCompetitorsList,
  getCorrigendumDocListData,
  getCountryNames,
  getDocListData,
  getPQDocListData,
  getResultDocListData,
  getStateNames,
} from "../API";

const CTContext = createContext();

export const CTContextProvider = ({ children }) => {
  const [loggedIn, setLoggedIn] = useState(false);
  const [countryNames, setCountryNames] = useState([]);
  const [stateNames, setStateNames] = useState([]);
  const [cityNames, setCityNames] = useState([]);
  const [userID, setUserID] = useState(null);
  const [userDetailId, setUserDetailId] = useState(null);
  const [domainId, setDomainId] = useState(null);
  const [name, setName] = useState();
  const [company, setCompany] = useState();
  const [designation, setDesignation] = useState();
  const [city, setCity] = useState();
  const [interestedIn, setInterestedIn] = useState();
  const [mobileNo, setMobileNo] = useState();
  const [clientDomainName, setClientDomainName] = useState();
  const [token, setToken] = useState();
  const [activeTab, setActiveTab] = useState(0);
  const [biddersDetails, setBiddersDetails] = useState({});
  const [competitorsDetails, setCompetitorsDetails] = useState({});
  const [resultDoc, setResultDoc] = useState({});
  const [doc, setDoc] = useState({});
  const [docCorrigendum, setDocCorrigendum] = useState({});
  const [docPQ, setDocPQ] = useState({});
  const [docBOQ, setDocBOQ] = useState({});

  const navigate = useNavigate();

  const login = () => {
    setLoggedIn(true);
  };

  const logout = () => {
    setLoggedIn(false);
  };

  const loginRequire = () => {
    if (loggedIn === false) {
      navigate("/signIn");
    }

    return null;
  };

  const fetchDoc = async (tcNo, tenderProcId) => {
    try {
      const docData = await getDocListData(
        parseInt(tcNo),
        parseInt(tenderProcId)
      );

      setDoc(docData);
    } catch (error) {
      console.error("Error fetching Tender Doc:", error);
    }
  };

  const fetchCorrigendumDoc = async (tcNo, tenderProcId) => {
    try {
      const docCorrigendumData = await getCorrigendumDocListData(
        parseInt(tcNo),
        parseInt(tenderProcId)
      );

      setDocCorrigendum(docCorrigendumData);
    } catch (error) {
      console.error("Error fetching Corri Doc:", error);
    }
  };

  const fetchPQDoc = async (tcNo, tenderProcId) => {
    try {
      const docPQData = await getPQDocListData(
        parseInt(tcNo),
        parseInt(tenderProcId)
      );

      setDocPQ(docPQData);
    } catch (error) {
      console.error("Error fetching PQ Doc:", error);
    }
  };

  const fetchBOQDoc = async (tcNo, tenderProcId) => {
    try {
      const docBOQData = await getBOQDocListData(
        parseInt(tcNo),
        parseInt(tenderProcId)
      );

      setDocBOQ(docBOQData);
    } catch (error) {
      console.error("Error fetching BOQ Doc:", error);
    }
  };

  const fetchResultDoc = async (resultID) => {
    try {
      const resultDocData = await getResultDocListData(parseInt(resultID));

      setResultDoc(resultDocData);
    } catch (error) {
      console.error("Error fetching Result Doc:", error);
    }
  };

  const fetchBiddersData = async (resultID) => {
    try {
      const biddersData = await getBiddersList(parseInt(resultID));

      setBiddersDetails(biddersData);
    } catch (error) {
      console.error("Error fetching Bidders:", error);
    }
  };

  const fetchCompetitorsData = async (resultID) => {
    try {
      const competitorsData = await getCompetitorsList(parseInt(resultID));

      setCompetitorsDetails(competitorsData);
    } catch (error) {
      console.error("Error fetching Competitors:", error);
    }
  };

  const fetchCountries = async () => {
    try {
      const countries = await getCountryNames();
      setCountryNames(countries);
    } catch (error) {
      console.error("Error fetching countries:", error);
    }
  };

  const fetchStates = async (selectedCountry) => {
    try {
      const states = await getStateNames(selectedCountry);
      setStateNames(states);
    } catch (error) {
      console.error("Error fetching states:", error);
    }
  };

  const fetchCities = async (selectedState) => {
    try {
      const cities = await getCityNames(selectedState);
      setCityNames(cities);
    } catch (error) {
      console.error("Error fetching cities:", error);
    }
  };

  const [searched, setSearched] = useState(null);
  const handleSearched = (value) => {
    setSearched(value);
  };

  const handleTabChange = (index) => {
    setActiveTab(index);
  };

  return (
    <CTContext.Provider
      value={{
        loggedIn,
        login,
        logout,
        loginRequire,

        countryNames,
        stateNames,
        cityNames,

        fetchCountries,
        fetchStates,
        fetchCities,

        searched,
        setSearched,
        handleSearched,

        activeTab,
        setActiveTab,
        handleTabChange,

        userID,
        setUserID,
        userDetailId,
        setUserDetailId,
        domainId,
        setDomainId,
        name,
        setName,
        company,
        setCompany,
        designation,
        setDesignation,
        city,
        setCity,
        interestedIn,
        setInterestedIn,
        mobileNo,
        setMobileNo,
        clientDomainName,
        setClientDomainName,

        token,
        setToken,

        biddersDetails,
        setBiddersDetails,
        competitorsDetails,
        setCompetitorsDetails,
        resultDoc,
        setResultDoc,
        doc,
        setDoc,
        docCorrigendum,
        setDocCorrigendum,
        docPQ,
        setDocPQ,
        docBOQ,
        setDocBOQ,

        fetchDoc,
        fetchCorrigendumDoc,
        fetchPQDoc,
        fetchBOQDoc,
        fetchResultDoc,
        fetchBiddersData,
        fetchCompetitorsData,
      }}
    >
      {children}
    </CTContext.Provider>
  );
};

export const useCTContext = () => useContext(CTContext);
