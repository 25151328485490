import React from "react";
import Slider from "react-slick";
import { Box, Card, CardMedia, Container, Typography } from "@mui/material";

export default function OurPartners() {
  const settings = {
    dots: false,
    infinite: true,
    speed: 900,
    slidesToShow: 6,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 771,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 601,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
    ],
  };

  return (
    <Box component={"section"} sx={{ bgcolor: "#f5f6ff", py: 10 }}>
      <Container
        maxWidth="lg"
        sx={{
          "& .slick-dots ": { position: "unset", mt: 3 },

          "& .slick-dots li button:before": {
            fontSize: 0,
            opacity: 1,
            height: 12,
            width: 12,
            border: "3px #8731E8 solid",
            borderRadius: "50%",
          },

          "& .slick-dots li.slick-active button:before": {
            fontSize: 18,
            color: "#8731E8",
            border: "none",
          },

          "& .slick-slide > div": {
            display: "flex",
            justifyContent: "center",
          },

          "& .slick-prev:before, .slick-next:before": { display: "none" },
        }}
      >
        <Box
          sx={{
            textAlign: "center",
            mb: "40px",
          }}
        >
          <Typography
            variant="h5"
            sx={{
              fontWeight: 700,
              fontSize: 26,
              color: "#000",
              letterSpacing: 0.5,
              "& span": { fontWeight: 600, color: "#333" },
            }}
          >
            <span>OUR </span>PARTNERS
          </Typography>
          <Typography variant="body1" color={"#666"} mt={1.4}>
            The Companies That Represent Us.
          </Typography>
        </Box>

        <Container>
          <Slider {...settings}>
            {partners.map((partner, index) => (
              <Card
                key={index}
                sx={{
                  bgcolor: "transparent",
                  boxShadow: "none",
                  maxWidth: "70%",
                  ":hover": {
                    boxShadow: "0 0 20px 10x blcak",
                  },
                  textAlign: "center",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <CardMedia
                  component={"img"}
                  src={partner.logo}
                  alt=""
                  sx={{ height: 82.5 }}
                />
              </Card>
            ))}
          </Slider>
        </Container>
      </Container>
    </Box>
  );
}

const partners = [
  { logo: "/images/Partners/1.png" },
  { logo: "/images/Partners/2.png" },
  { logo: "/images/Partners/3.png" },
  { logo: "/images/Partners/4.png" },
  { logo: "/images/Partners/5.png" },
  { logo: "/images/Partners/6.png" },
  { logo: "/images/Partners/7.png" },
  { logo: "/images/Partners/8.png" },
  { logo: "/images/Partners/9.png" },
  { logo: "/images/Partners/10.png" },
];
