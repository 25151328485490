import React, { useState } from "react";
import { styled, alpha } from "@mui/material/styles";

import {
  Menu,
  MenuItem,
  Divider,
  Avatar,
  Button,
  Badge,
  Typography,
} from "@mui/material";

import {
  EditNote,
  Logout,
  ArrowDropDown,
  Settings,
  Speed,
} from "@mui/icons-material";
import { NavLink } from "react-router-dom";
import { useCTContext } from "../../Context/CTContext";
// import { getDashboardTenderList } from "../../API";

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
    sx={{
      top: "23px",
      "&  div  a": {
        textDecoration: "none",
        color: "#707070",
      },
    }}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

export default function ProfileMenuButton({ transparency }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const {
    name,
    logout,
    // userDetailId, token,
    loggedIn,
  } = useCTContext();
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // loggedIn && console.log(userDetailId, token);

  const StyledBadge = styled(Badge)(({ theme }) => ({
    "& .MuiBadge-badge": {
      backgroundColor: "#44b700",
      color: "#44b700",
      boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
      "&::after": {
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        borderRadius: "50%",
        animation: "ripple 1.2s infinite ease-in-out",
        border: "1px solid currentColor",
        content: '""',
      },
      "&:hover": {
        backgroundColor: "#44b700 !important",
        color: "#44b700 !important",
      },
    },

    "@keyframes ripple": {
      "0%": {
        transform: "scale(.8)",
        opacity: 1,
      },
      "100%": {
        transform: "scale(2.4)",
        opacity: 0,
      },
    },
  }));

  const handleLogoutClick = () => {
    handleClose();
    logout();
  };

  const handleDashBoard = () => {
    handleClose();
  };

  const username = name !== undefined ? name?.split(" ")[0] : "User";

  return (
    <>
      <Button
        id="demo-customized-button"
        aria-controls={open ? "demo-customized-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        variant="text"
        disableElevation
        onClick={handleClick}
        endIcon={<ArrowDropDown />}
        sx={{
          color: transparency === 1 ? "#707070" : "#fff",
          textTransform: "capitalize",
          ml: 1,
        }}
      >
        <StyledBadge
          overlap="circular"
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          variant="dot"
        >
          <Avatar
            src="/images/icons/userDefault.svg"
            alt=""
            sx={{
              boxShadow: "0px 0px 0px 5px rgba(255, 255, 255, 0.2)",
              border: "3px solid",
              borderRadius: "50%",
              bgcolor: "#ccc !important",
              color: "#FFF",

              ":hover": {
                borderRadius: "50% !important",
                bgcolor: "#ccc !important",
              },
              borderColor: transparency === 1 ? "#8731E8" : "#fff",
            }}
          />
        </StyledBadge>

        <Typography
          sx={{
            ml: 1.4,
            display: { md: "flex", xs: "none" },
          }}
        >
          Hi, {username ? username : "User"}
        </Typography>
      </Button>

      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          "aria-labelledby": "demo-customized-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <NavLink to={"/editProfile"}>
          <MenuItem onClick={handleClose}>
            <EditNote />
            Edit Profile
          </MenuItem>
        </NavLink>

        <Divider sx={{ my: 0.5 }} />

        {loggedIn && (
          <NavLink
            to={"/tenders"}
            // state={{
            //   dashboard: getDashboardTenderList(
            //     parseInt(userDetailId),
            //     token.toString()
            //   ),
            // }}
          >
            <MenuItem onClick={handleDashBoard}>
              <Speed />
              Dashboard
            </MenuItem>
          </NavLink>
        )}

        <Divider sx={{ my: 0.5 }} />

        <NavLink to={"/settings"}>
          <MenuItem onClick={handleClose}>
            <Settings />
            Settings
          </MenuItem>
        </NavLink>

        <Divider sx={{ my: 0.5 }} />

        <NavLink to={"/"}>
          <MenuItem onClick={handleLogoutClick}>
            <Logout />
            Log Out
          </MenuItem>
        </NavLink>
      </StyledMenu>
    </>
  );
}
