import React, { useEffect, useState } from "react";
import Poster from "../OtherComponents/Poster";
import {
  Box,
  Button,
  Container,
  FormControl,
  MenuItem,
  Select,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { Place } from "@mui/icons-material";
import { doRegisteration } from "../../API";
import { useCTContext } from "../../Context/CTContext";

export default function Register() {
  const {
    countryNames,
    stateNames,
    cityNames,
    fetchCountries,
    fetchStates,
    fetchCities,
  } = useCTContext();

  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedState, setSelectedState] = useState("");
  const [selectedCity, setSelectedCity] = useState("");
  const [registrationError, setRegistrationError] = useState("");
  const [registrationSuccess, setRegistrationSuccess] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    fetchCountries();
    if (selectedCountry !== "") {
      fetchStates(selectedCountry);
      if (selectedState !== "") {
        fetchCities(selectedState);
      }
    }
  }, [
    fetchCountries,
    fetchStates,
    fetchCities,
    selectedCountry,
    selectedState,
  ]);

  const handleChange = (event, setStateFunction) => {
    setStateFunction(event.target.value);
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    try {
      await doRegisteration();
      console.log("Registration Successful!");
      setRegistrationSuccess(true);
      setTimeout(() => {
        navigate("/signIn");
      }, 3000);
    } catch (error) {
      setRegistrationError(error.message);
    }
  };

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: 266,
        width: 200,
      },
    },
  };

  return (
    <>
      <Poster title="Register" />

      <Box
        component={"form"}
        onSubmit={handleFormSubmit}
        sx={{
          py: 5,
          "& a": {
            textDecoration: "none",
            fontWeight: 600,
            color: "#963aff",
            ":hover": { color: "purple" },
          },
          color: "#666",
          bgcolor: "#FFF",
        }}
      >
        <Container
          sx={{
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            justifyContent: { md: "space-between", xs: "center" },
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              backgroundImage: "url('/images/Register_Login_BG.png')",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center center",
              backgroundSize: "contain",
              display: { sm: "block", xs: "none" },
              height: "100vh",
              width: "50%",
            }}
          />

          <Box
            sx={{
              width: "46%",
              ".MuiBox-root": {
                width: "100%",
                mb: 2,
              },
            }}
          >
            <Box>
              <Typography>Company Name *</Typography>
              <TextField size="small" fullWidth color="secondary" required />
            </Box>

            <Box>
              <Typography>Person Name *</Typography>
              <TextField size="small" fullWidth color="secondary" required />
            </Box>

            <Box>
              <Typography>Country *</Typography>
              <FormControl size="small" fullWidth color="secondary" required>
                <Select
                  displayEmpty
                  value={selectedCountry}
                  onChange={(e) => handleChange(e, setSelectedCountry)}
                  MenuProps={MenuProps}
                  inputProps={{ "aria-label": "Without label" }}
                  sx={{ m: 0, textTransform: "capitalize" }}
                >
                  <MenuItem disabled value="">
                    <em style={{ color: "#666" }}>
                      <Place sx={{ color: "#963aff", fontSize: 16, mr: 0.5 }} />
                      Country
                    </em>
                  </MenuItem>

                  {countryNames.map((country, i) => (
                    <MenuItem
                      key={i}
                      value={country.countryname}
                      sx={{ textTransform: "capitalize" }}
                    >
                      {country.countryname}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>

            <Box>
              <Typography>State *</Typography>
              <FormControl
                color="secondary"
                fullWidth
                size="small"
                sx={{
                  mt: { xs: 2, md: 0 },
                  borderRadius: 2,
                  border: "solid transparent",
                  ":hover": { border: "solid #8731E8" },
                }}
              >
                <Select
                  value={selectedState}
                  MenuProps={MenuProps}
                  onChange={(e) => handleChange(e, setSelectedState)}
                  sx={{ textTransform: "capitalize" }}
                >
                  {stateNames.map((state, i) => (
                    <MenuItem
                      key={i}
                      value={state}
                      sx={{ textTransform: "capitalize" }}
                    >
                      {state}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>

            <Box>
              <Typography>City *</Typography>
              <FormControl
                color="secondary"
                fullWidth
                size="small"
                sx={{
                  mt: { xs: 2, md: 0 },
                  borderRadius: 2,
                  border: "solid transparent",
                  ":hover": { border: "solid #8731E8" },
                }}
              >
                <Select
                  value={selectedCity}
                  MenuProps={MenuProps}
                  onChange={(e) => handleChange(e, setSelectedCity)}
                  sx={{ textTransform: "capitalize" }}
                >
                  {cityNames.map((city, i) => (
                    <MenuItem
                      key={i}
                      value={city}
                      sx={{ textTransform: "capitalize" }}
                    >
                      {city}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>

            <Box>
              <Typography>Mobile No. *</Typography>
              <TextField
                type="tel"
                size="small"
                fullWidth
                color="secondary"
                required
              />
            </Box>

            <Box>
              <Typography>Email *</Typography>
              <TextField
                type="email"
                size="small"
                fullWidth
                color="secondary"
                required
              />
            </Box>

            <Box>
              <Typography>Tenders Interested In *</Typography>
              <TextField
                size="small"
                fullWidth
                color="secondary"
                required
                // placeholder="Write Comma Seperated Values. E.g.: (Road, Bridge)"
              />
            </Box>

            <Box>
              <Typography>Enter Verification Code *</Typography>
              <TextField
                type="number"
                size="small"
                fullWidth
                color="secondary"
                required
              />
            </Box>

            {registrationError && (
              <Typography sx={{ color: "red", textAlign: "center", mb: 2 }}>
                {registrationError}
              </Typography>
            )}

            <Box sx={{ mt: 5, textAlign: "center" }}>
              <Button
                type="submit"
                variant="contained"
                color="secondary"
                sx={{
                  py: 1.8,
                  width: { sm: "50%", xs: "100%" },
                  mb: 2,
                  borderRadius: 9,
                  textTransform: "capitalize",
                  bgcolor: "#963aff",
                }}
              >
                Register Now!
              </Button>

              <Typography sx={{ fontSize: 14 }}>
                Already Have an Accont ? <Link to="/signIn">Sign In</Link>
              </Typography>

              <Snackbar
                open={registrationSuccess}
                message="Registration Done Successfully!"
                autoHideDuration={5000}
                onClose={() => setRegistrationSuccess(false)}
              />
            </Box>
          </Box>
        </Container>
      </Box>
    </>
  );
}
