import React, { useRef } from "react";
import {
  Box,
  Typography,
  Container,
  IconButton,
  // Avatar,
  CardMedia,
  Card,
} from "@mui/material";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {
  ArrowBackIosRounded,
  ArrowForwardIosRounded,
} from "@mui/icons-material";

export default function LatestTendersSlider() {
  const sliderRef = useRef(null);

  const settings = {
    dots: false,
    infinite: true,
    speed: 900,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 771,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <Box component={"section"}>
      <Container
        maxWidth="lg"
        sx={{
          my: 10,

          "& .slick-slide > div": {
            display: "flex",
            justifyContent: "center",
          },

          "& .slick-prev:before, .slick-next:before": {
            display: "none",
          },
        }}
      >
        <Box
          sx={{
            textAlign: "center",
            mb: "40px",
          }}
        >
          <Typography
            variant="h5"
            sx={{
              fontWeight: 700,
              fontSize: 26,
              color: "#000",
              letterSpacing: 0.5,
              "& span": { fontWeight: 600, color: "#333" },
            }}
          >
            <span>CLIENTS </span>TESTIMONIALS
          </Typography>
          <Typography variant="body1" color={"#666"} mt={1.4}>
            We Collect Reviews From Our Customers.
          </Typography>
        </Box>

        <Container>
          <Slider {...settings} ref={sliderRef}>
            {testimonialsData.map((testimonial, index) => (
              <Card
                key={index}
                sx={{
                  border: "1px solid #ededed",
                  p: { sm: "56px 53px", xs: 3.2 },
                  boxShadow: "none",
                  borderRadius: 0,
                  maxWidth: { md: "76%", xs: "100%" },
                }}
              >
                <Typography
                  variant="body2"
                  textAlign={"justify"}
                  color={"#666"}
                >
                  {testimonial.content}
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "flex-end",
                    justifyContent: "space-between",
                    mt: "20px",
                    width: "100%",
                  }}
                >
                  <Box
                    sx={{
                      display: { sm: "flex", xs: "unset" },
                      alignItems: "flex-end",
                    }}
                  >
                    {/* <Box
                      component={"span"}
                      sx={{
                        mr: 3,
                        display: "flex",
                        "&::before": {
                          content: '""',
                          display: "block",
                          position: "relative",
                          left: 0,
                          top: 0,
                          width: "60px",
                          height: "60px",
                          padding: "4px",
                          borderRadius: "50%",
                          background:
                            "linear-gradient(90deg, rgba(240, 71, 134, 0.75) 0%, rgba(125, 107, 255, 0.75) 100%)",
                        },
                      }}
                    >
                      <Avatar
                        src={testimonial.avatarSrc}
                        alt={""}
                        sx={{
                          height: 60,
                          width: 60,
                          position: "absolute",
                          mt: "4px",
                          ml: "4px",
                        }}
                      />
                    </Box> */}

                    <Box>
                      <Typography variant="h6">{testimonial.name}</Typography>
                      <Typography variant="body2" color={"#666"}>
                        {testimonial.location}
                      </Typography>
                    </Box>
                  </Box>

                  <CardMedia
                    component={"img"}
                    src="/images/Icons/quote.png"
                    sx={{
                      position: "relative",
                      right: 0,
                      bottom: 0,
                      width: "68px",
                      height: "58px",
                      mx: 0,
                      ml: 1,
                    }}
                  />
                </Box>
              </Card>
            ))}
          </Slider>
        </Container>

        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            mt: { sm: 7, xs: 3 },
          }}
        >
          <IconButton
            onClick={() => {
              sliderRef.current.slickPrev();
            }}
            color="primary"
            aria-label="previous"
            sx={{
              color: "#70778b",
              border: "2px solid #ddd",

              ":hover": { bgcolor: "#8731E8", color: "#FFF" },
              ":hover path": { stroke: "#FFF" },
            }}
          >
            <ArrowBackIosRounded sx={{ path: { stroke: "#70778b" } }} />
          </IconButton>

          <IconButton
            onClick={() => {
              sliderRef.current.slickNext();
            }}
            color="primary"
            aria-label="next"
            sx={{
              color: "#70778b",
              border: "2px solid #ddd",
              ml: 1.4,
              ":hover": { bgcolor: "#8731E8", color: "#FFF" },
              ":hover path": { stroke: "#FFF" },
            }}
          >
            <ArrowForwardIosRounded sx={{ path: { stroke: "#70778b" } }} />
          </IconButton>
        </Box>
      </Container>
    </Box>
  );
}

const testimonialsData = [
  {
    content:
      "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore gna a. Ut enim ad minim veniam,",
    avatarSrc: "/images/Testimonials/ts-1.jpg",
    name: "Mary Deshaw",
    location: "Chicago",
  },
  {
    content:
      "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore gna a. Ut enim ad minim veniam,",
    avatarSrc: "/images/Testimonials/ts-2.jpg",
    name: "Jhon Morris",
    location: "Los Angeles",
  },
  {
    content:
      "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore gna a. Ut enim ad minim veniam,",
    avatarSrc: "/images/Testimonials/ts-3.jpg",
    name: "Gary Steven",
    location: "Philadelphia",
  },
  {
    content:
      "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore gna a. Ut enim ad minim veniam,",
    avatarSrc: "/images/Testimonials/ts-5.jpg",
    name: "Christy Mayer",
    location: "San Fancisco",
  },
  {
    content:
      "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore gna a. Ut enim ad minim veniam,",
    avatarSrc: "/images/Testimonials/ts-6.jpg",
    name: "Ichiro Tasaka",
    location: "Houston",
  },
];
