//----------------------------
// Get All List Of Tenders API

const getTenderList = async (options) => {
  const { keyword = "", fromData = 0, sortBy = "", sortColumn = "" } = options;

  const requestBody = {
    tabindex: 1,
    searchkeyword: keyword === "" ? null : keyword, //"road repair",
    tender_typewise: "live",
    categoryflag: "1",
    domainname: "construction.tendertiger.com",
    total: 10,
    last_rescount: 0,

    fromdata: fromData, // For Pagination (Increment By 1)

    sortingby: sortBy === "" ? null : sortBy,
    sortcolumnname: sortColumn === "" ? null : sortColumn,
  };

  console.log("From Data: ", fromData);

  try {
    const response = await fetch(
      //Local URL
      // "http://192.168.101.75:5008/api/Tender/GetTendersListBeforeLogin",

      //Live URL
      "https://tendersearch.tendertiger.co.in/api/Tender/GetTendersListBeforeLogin",
      {
        method: "POST",
        body: JSON.stringify(requestBody),
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    if (response.status === 200) {
      const jsonResponse = await response.json();
      return jsonResponse.TenderList;
    } else {
      throw new Error(response.statusText);
    }
  } catch (error) {
    throw new Error(error);
  }
};

export { getTenderList };

//----------------------------
// Get Details API

const getTenderDetails = async (tcno, tenderId) => {
  const requestBody = {
    TcNo: tcno, //62643141,
    TenderprocId: tenderId, //64915681,
    CustomerId: 0,
    UserDetailId: 0,
    SubNo: 0,
    IPAddress: null,
    SearchKeyword: null,
    TenderType: "Live",
    Year: 0,
    mailTrackId: "",
  };

  try {
    const response = await fetch(
      //Local URL
      // "http://192.168.101.75:5050/api/Tender/GetTenderDetails1",

      //Live URL
      "https://tenderdetail.tendertiger.co.in/api/Tender/GetTenderDetails1",
      {
        method: "POST",
        body: JSON.stringify(requestBody),
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    if (response.status === 200) {
      const jsonResponse = await response.json();
      return jsonResponse;
    } else {
      throw new Error(response.statusText);
    }
  } catch (error) {
    throw new Error(error);
  }
};

export { getTenderDetails };

//----------------------------
// Get Country Names API

const getCountryNames = async () => {
  try {
    const response = await fetch(
      //Local URL
      // "http://192.168.101.75:5078/api/country/GetCountries",

      //Live URL
      "https://common.tendertiger.co.in/api/CountryStateCity/GetCountries",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    if (response.status === 200) {
      const jsonResponse = await response.json();
      return jsonResponse;
    } else {
      throw new Error(response.statusText);
    }
  } catch (error) {
    throw new Error(error);
  }
};

export { getCountryNames };

//----------------------------
// Get State Names API

const getStateNames = async (country) => {
  const requestBody = {
    CountryName: `${country}`,
  };

  try {
    const response = await fetch(
      //Local URL
      // `http://192.168.101.75:5083/api/state/GetStates`,

      //Live URL
      "https://common.tendertiger.co.in/api/CountryStateCity/GetStates",
      {
        method: "POST",
        body: JSON.stringify(requestBody),
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    if (response.status === 200) {
      const jsonResponse = await response.json();
      if (
        Array.isArray(jsonResponse) &&
        jsonResponse.length > 0 &&
        jsonResponse[0].statenames
      ) {
        return jsonResponse[0].statenames;
      } else {
        return [];
      }
    } else {
      throw new Error(response.statusText);
    }
  } catch (error) {
    throw new Error(error);
  }
};

export { getStateNames };

//----------------------------
// Get City Names API

const getCityNames = async (stateName) => {
  const requestBody = {
    StateName: `${stateName}`,
  };
  try {
    const response = await fetch(
      //Local URL
      // `http://192.168.101.75:5082/api/city/GetCities`,

      //Live URL
      "https://common.tendertiger.co.in/api/CountryStateCity/GetCities",
      {
        method: "POST",
        body: JSON.stringify(requestBody),
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    if (response.status === 200) {
      const jsonResponse = await response.json();
      if (
        Array.isArray(jsonResponse) &&
        jsonResponse.length > 0 &&
        jsonResponse[0].citynames
      ) {
        return jsonResponse[0].citynames;
      } else {
        return [];
      }
    } else {
      throw new Error(response.statusText);
    }
  } catch (error) {
    throw new Error(error);
  }
};

export { getCityNames };

//----------------------------
// User Registration API

const doRegisteration = async (keyword) => {
  const requestBody = {
    CityId: 783,
    CompanyName: "Tendertiger",
    ConfirmationStatus: 0,
    CountryISDCode: "91",
    Designation: "testing",
    Email: "freshtender@tigertenders.com",
    HostUrl: "https://Registration.tendertiger.co.in/",
    IntrestedIn: "road,r c c pipe",
    MobileNo: "7041928848",
    Name: "Priyanka",
    Password: "test@123",
    UserIPAddress: "",
    WebDomainUrl: "Registration.tendertiger.co.in",
  };

  try {
    const response = await fetch(
      //Local URL - Not Working
      // "http://192.168.101.75:5067/api/Registration/UserRegistration",

      //Live URL
      "https://Registration.tendertiger.co.in/api/Registration/UserRegistration",
      {
        method: "POST",
        body: JSON.stringify(requestBody),
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    if (response.status === 200) {
      const jsonResponse = await response.json();
      return jsonResponse.TenderList;
    } else {
      throw new Error(response.statusText);
    }
  } catch (error) {
    throw new Error(error);
  }
};

export { doRegisteration };

//----------------------------
// User Log In API

const checkLogIn = async (email, password, remember) => {
  const requestBody = {
    email, //"hemal.kabani@savex.in",
    Password: password, //"123**SA",
    MACAddress: "",
    Hosturl: "https://registration.tendertiger.co.in/",
    DomainUrl: "tendertiger.co.in",
    KeepLoggedIn: remember,
  };

  try {
    const response = await fetch(
      //Local URL - Not Working/Available
      // "",

      //Live URL
      "https://registration.tendertiger.co.in/api/Registration/Login",
      {
        method: "POST",
        body: JSON.stringify(requestBody),
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    if (response.status === 200) {
      const jsonResponse = await response.json();
      return jsonResponse;
    } else {
      throw new Error(response.statusText);
    }
  } catch (error) {
    throw new Error(error);
  }
};

export { checkLogIn };

//----------------------------
// Get List Of Tender Results API

const getResultList = async (options) => {
  const {
    keyword = "",
    total = 0,
    lastCount = 0,
    fromData = 0,
    sortBy = "",
    sortColumn = "",
  } = options;

  const requestBody = {
    searchkeyword: keyword === "" ? null : keyword,
    tabindex: 1,
    maintabindex: 0,
    page: 0,
    limit: 0,
    profilequery: "undefined",
    subscribetype: "all",
    custid: 0,
    custsubno: 0,
    sub_no: 0,
    loc_type: "undefined",
    rescount: 0,
    globeltype: null,
    tendertypewise: null,
    hdn_status: 0,
    tender_typewise: "live",
    categoryflag: "1",
    userdetailid: 0,
    domainname: "construction.tendertiger.com",
    seosearchlocation: "search",
    last_categoryflag: null,
    querytype: null,

    total:10,
    last_rescount: lastCount === "" ? 0 : lastCount,
    fromdata: fromData === "" ? 0 : fromData,

    sortingby: sortBy === "" ? null : sortBy,
    sortcolumnname: sortColumn === "" ? null : sortColumn,
  };

  try {
    const response = await fetch(
      //Local URL
      // "",

      //Live URL
      "https://resultsearch.tendertiger.co.in/api/Result/GetResultListBeforeLogin",
      {
        method: "POST",
        body: JSON.stringify(requestBody),
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    if (response.status === 200) {
      const jsonResponse = await response.json();
      console.log("Result List: ", jsonResponse);
      return jsonResponse.TenderList;
    } else {
      throw new Error(response.statusText);
    }
  } catch (error) {
    throw new Error(error);
  }
};

export { getResultList };

//----------------------------
// Get Detailed Result API

const getResultDetails = async (resultID) => {
  const requestBody = {
    ResultId: resultID,
  };

  try {
    const response = await fetch(
      //Local URL
      // "",

      //Live URL
      "https://resultdetail.tendertiger.co.in/api/TenderResultDetail/GetTenderResultDetails",
      {
        method: "POST",
        body: JSON.stringify(requestBody),
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    if (response.status === 200) {
      const jsonResponse = await response.json();
      console.log("Result Details: ", jsonResponse);
      return jsonResponse;
    } else {
      throw new Error(response.statusText);
    }
  } catch (error) {
    throw new Error(error);
  }
};

export { getResultDetails };

//----------------------------
// Get User's Data API

const getUserData = async (userID, userDetailID, domainID) => {
  try {
    const response = await fetch(
      //Local URL
      // "",

      //Live URL
      `https://registration.tendertiger.co.in/api/Registration/GetProfile?userId=${userID}&userDetailId=${userDetailID}&DomainId=${domainID}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    if (response.status === 200) {
      const jsonResponse = await response.json();
      return jsonResponse;
    } else {
      throw new Error(response.statusText);
    }
  } catch (error) {
    throw new Error(error);
  }
};

export { getUserData };

//----------------------------
// Dashboard API

const getDashboardTenderList = async (userDetailId, token) => {
  const requestBody = {
    searchkeyword: null,
    tabindex: 1,
    maintabindex: 0,
    page: 0,
    total: 20,
    limit: 0,
    sortby: null,
    search: null,
    direction: null,
    sub_no: 0,
    profilequery: "undefined",
    index: null,
    query: null,
    term: null,
    tenderrefno: "",
    orgname: null,
    subindustry: null,
    minprice: 0,
    maxprice: 0,
    tenderdatetype: null,
    subscribetype: "all",
    custid: 0,
    custsubno: 0,
    macaddress: null,
    loc_type: null,
    regionname: null,
    countryname: null,
    cityname: null,
    statename: null,
    tendertype: null,
    tenderstatus: "Live",
    tendercompetition: null,
    document: null,
    mfa: null,
    keywordsearch: null,
    productname: null,
    searchword: null,
    tenderhostdate: null,
    tenderclosedate: null,
    tender_typewise: "live",
    exactphrase: null,
    gemtender: null,
    rescount: 0,
    globeltype: null,
    orderbytype: null,
    categoryflag: "1",
    tendertypewise: null,
    companysubindustry: null,
    userdetailid: userDetailId,
    sortcolumnname: null,
    sortingby: null,
    archive: "",
    domainname: "tendertiger.co.in",
    ForExcell: false,
    seosearchlocation: "search",
    querytype: "null",
    last_categoryflag: 1,
    last_rescount: 0,
    fromdata: 0,
  };

  try {
    const response = await fetch(
      //Local URL
      // "",

      //Live URL
      "https://tendersearch.tendertiger.co.in/api/Dashboard/GetTendersListDashboard",
      {
        method: "POST",
        body: JSON.stringify(requestBody),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (response.status === 200) {
      const jsonResponse = await response.json();
      console.log(jsonResponse.TenderList);
      return jsonResponse.TenderList;
    } else {
      throw new Error(response.statusText);
    }
  } catch (error) {
    throw new Error(error);
  }
};

export { getDashboardTenderList };

//----------------------------
// Update User Profile API

const updateProfile = async (
  userID,
  userDetailID,
  domainID,
  name,
  companyName,
  designation,
  city,
  interestedIn,
  clientDomainName
) => {
  const requestBody = {
    UserId: userID,
    UserDetailId: userDetailID,
    EmailId: null,
    CountryISDCode: null,
    Name: name,
    CompanyName: companyName,
    DomainId: domainID,
    Designation: designation,
    CityId: 0,
    CityName: city,
    MobileNo: null,
    InterestedIn: interestedIn,
    IPAddress: "",
    ClientDomainName: clientDomainName || null,
  };

  try {
    const response = await fetch(
      //Local URL - Not Working/Available
      // "",

      //Live URL
      "https://Registration.tendertiger.co.in/api/Registration/UpdateProfile",
      {
        method: "POST",
        body: JSON.stringify(requestBody),
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    if (response.status === 200) {
      const jsonResponse = await response.json();
      return jsonResponse;
    } else {
      throw new Error("response.statusText");
    }
  } catch (error) {
    throw new Error(error);
  }
};

export { updateProfile };

//----------------------------
// Get Bidders List API

const getBiddersList = async (resultID) => {
  const requestBody = {
    ResultId: resultID,
  };

  try {
    const response = await fetch(
      //Local URL
      // "",

      //Live URL
      "https://resultdetail.tendertiger.co.in/api/Bidder/GetTenderBidderList",
      {
        method: "POST",
        body: JSON.stringify(requestBody),
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    if (response.status === 200) {
      const jsonResponse = await response.json();
      console.log("Bidders: ", jsonResponse);
      return jsonResponse;
    } else {
      throw new Error(response.statusText);
    }
  } catch (error) {
    throw new Error(error);
  }
};

export { getBiddersList };

//----------------------------
// Get Competitors List API

const getCompetitorsList = async (resultID) => {
  const requestBody = {
    ResultId: resultID,
  };

  try {
    const response = await fetch(
      //Local URL
      // "",

      //Live URL
      "https://resultdetail.tendertiger.co.in/api/Competitor/GetTenderCompetitorList",
      {
        method: "POST",
        body: JSON.stringify(requestBody),
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    if (response.status === 200) {
      const jsonResponse = await response.json();
      console.log("Competitors: ", jsonResponse);
      return jsonResponse;
    } else {
      throw new Error(response.statusText);
    }
  } catch (error) {
    throw new Error(error);
  }
};

export { getCompetitorsList };

//----------------------------
// Get Document List API

const getDocListData = async (tcNo, tenderProcId) => {
  const requestBody = {
    TcNo: tcNo,
    TenderType: "Live",
    TenderProcId: tenderProcId,
  };

  try {
    const response = await fetch(
      //Local URL
      // "",

      //Live URL
      "https://tenderdetail.tendertiger.co.in/api/Tender/GetTenderDocuments1",
      {
        method: "POST",
        body: JSON.stringify(requestBody),
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    if (response.status === 200) {
      const jsonResponse = await response.json();
      console.log("Doc", jsonResponse);
      return jsonResponse;
    } else {
      throw new Error(response.statusText);
    }
  } catch (error) {
    throw new Error(error);
  }
};

export { getDocListData };

//----------------------------
// Get Corrigendum Document List API

const getCorrigendumDocListData = async (tcNo, tenderProcId) => {
  const requestBody = {
    TcNo: tcNo,
    TenderType: "Live",
    TenderProcId: tenderProcId,
  };

  try {
    const response = await fetch(
      //Local URL
      // "",

      //Live URL
      "https://tenderdetail.tendertiger.co.in/api/Tender/GetTenderCorrigendum1",
      {
        method: "POST",
        body: JSON.stringify(requestBody),
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    if (response.status === 200) {
      const jsonResponse = await response.json();
      console.log("Cor Doc: ", jsonResponse);
      return jsonResponse;
    } else {
      throw new Error(response.statusText);
    }
  } catch (error) {
    throw new Error(error);
  }
};

export { getCorrigendumDocListData };

//----------------------------
// Get PQ Document List API

const getPQDocListData = async (tcNo, tenderProcId) => {
  const requestBody = {
    TcNo: tcNo,
    TenderType: "Live",
    TenderProcId: tenderProcId,
  };

  try {
    const response = await fetch(
      //Local URL
      // "",

      //Live URL
      "https://tenderdetail.tendertiger.co.in/api/Tender/GetTenderPQDocuments1",
      {
        method: "POST",
        body: JSON.stringify(requestBody),
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    if (response.status === 200) {
      const jsonResponse = await response.json();
      console.log("PQ Doc:", jsonResponse);
      return jsonResponse;
    } else {
      throw new Error(response.statusText);
    }
  } catch (error) {
    throw new Error(error);
  }
};

export { getPQDocListData };

//----------------------------
// Get BOQ Document List API

const getBOQDocListData = async (tcNo, tenderProcId) => {
  const requestBody = {
    TcNo: tcNo,
    TenderType: "Live",
    TenderProcId: tenderProcId,
  };

  try {
    const response = await fetch(
      //Local URL
      // "",

      //Live URL
      "https://tenderdetail.tendertiger.co.in/api/Tender/GetTenderBOQ1",
      {
        method: "POST",
        body: JSON.stringify(requestBody),
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    if (response.status === 200) {
      const jsonResponse = await response.json();
      console.log("BOQ Doc", jsonResponse);
      return jsonResponse;
    } else {
      throw new Error(response.statusText);
    }
  } catch (error) {
    throw new Error(error);
  }
};

export { getBOQDocListData };

//----------------------------
// Get Result Document List API

const getResultDocListData = async (resultID) => {
  const requestBody = {
    ResultId: resultID,
  };

  try {
    const response = await fetch(
      //Local URL
      // "",

      //Live URL
      "https://resultdetail.tendertiger.co.in/api/TenderResultDetail/GetTenderResultDocuments",
      {
        method: "POST",
        body: JSON.stringify(requestBody),
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    if (response.status === 200) {
      const jsonResponse = await response.json();
      console.log("Result Doc", jsonResponse);
      return jsonResponse;
    } else {
      throw new Error(response.statusText);
    }
  } catch (error) {
    throw new Error(error);
  }
};

export { getResultDocListData };

//----------------------------
// Download Document API

const downloadDocument = async (fileName) => {
  try {
    const response = await fetch(
      //Local URL
      // "",

      //Live URL
      `https://tenderdetail.tendertiger.co.in/api/Tender/DownloadDocument?filename=${fileName}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    if (response.status === 200) {
      const jsonResponse = await response.json();
      console.log("Download Doc", jsonResponse);
      return jsonResponse;
    } else {
      throw new Error(response.statusText);
    }
  } catch (error) {
    throw new Error(error);
  }
};

export { downloadDocument };
