import React from "react";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Container,
  Typography,
} from "@mui/material";
import { EmojiEvents, FolderCopy, PersonAdd, List } from "@mui/icons-material";

export default function AboutDataCount() {
  return (
    <Box
      component={"section"}
      sx={{
        "& a": { textDecoration: "none", color: "#000" },
        width: "100%",
        background:
          "linear-gradient(rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8)), url('/images/AboutBG.jpg') center center;",
        backgroundAttachment: "fixed",
        backgroundSize: "auto 100%",
        py: 6.4,
      }}
    >
      <Container
        sx={{
          display: "flex",
          justifyContent: { md: "space-between", xs: "center" },
          alignItems: "center",
          flexWrap: "wrap",
        }}
      >
        {Counters.map((count, index) => (
          <Card
            key={index}
            sx={{
              boxShadow: "none",
              bgcolor: "transparent",
              width: { md: "25%", sm: "50%", xs: "100%" },
              display: "flex",
              justifyContent: "center",
            }}
          >
            <CardContent sx={{ display: "flex", alignItems: "center" }}>
              {count.icon}

              <CardHeader
                title={
                  <Typography variant="h4" sx={{ fontWeight: 600 }}>
                    {count.count}
                  </Typography>
                }
                subheader={
                  <Typography sx={{ fontWeight: 600, mt: 1, fontSize: 18 }}>
                    {count.countTitle}
                  </Typography>
                }
              />
            </CardContent>
          </Card>
        ))}
      </Container>
    </Box>
  );
}

const Counters = [
  {
    icon: <FolderCopy sx={{ color: "#8731E8", mr: 1, fontSize: 55 }} />,
    count: 300,
    countTitle: "Categories",
  },
  {
    icon: <List sx={{ color: "#8731E8", mr: 1, fontSize: 82 }} />,
    count: 400,
    countTitle: "Listings",
  },
  {
    icon: <PersonAdd sx={{ color: "#8731E8", mr: 1, fontSize: 55 }} />,
    count: 250,
    countTitle: "Users",
  },
  {
    icon: <EmojiEvents sx={{ color: "#8731E8", mr: 1, fontSize: 55 }} />,
    count: 200,
    countTitle: "Won Awards",
  },
];
