import React from "react";
import { Container } from "@mui/material";
import TendersList from "../OtherComponents/TendersList";
import Poster from "../OtherComponents/Poster";
import { CTContextProvider, useCTContext } from "../../Context/CTContext";

const Tenders = () => {
  const { activeTab } = useCTContext();

  return (
    <>
      <CTContextProvider>
        <Poster
          bgHeight={258}
          title={
            (activeTab === 0 && "All Tenders") ||
            (activeTab === 1 && "All Tender Results")
          }
        />
      </CTContextProvider>

      <Container sx={{ my: 10 }}>
        <TendersList />
      </Container>
    </>
  );
};

export default Tenders;
