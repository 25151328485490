import {
  Box,
  Card,
  CardMedia,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { NavLink } from "react-router-dom";
import ProfileMenuButton from "./ProfileMenuButton";
import HamBurgerMenu from "./HamBurgerMenu";
import { useCTContext } from "../../Context/CTContext";

export default function Header() {
  const { loggedIn } = useCTContext();
  const headerRef = useRef(null);
  const [transparency, setTransparency] = useState();
  const [imageSrc, setImageSrc] = useState(
    "/images/Logo/ConstructionTenders_W.svg"
  );

  useEffect(() => {
    const handleScroll = () => {
      if (headerRef.current) {
        const scrollPosition = window.scrollY;
        const transitionPosition = 284;

        setTransparency(Math.min(scrollPosition / transitionPosition, 1));
        const textColor =
          scrollPosition > transitionPosition ? "#707070" : "#fff";

        const backgroundColor =
          scrollPosition < transitionPosition
            ? "rgba(255,255,255,0.1)"
            : `rgba(255,255,255,${transparency})`;

        setImageSrc(
          scrollPosition < transitionPosition
            ? "/images/Logo/ConstructionTenders_W.svg"
            : "/images/Logo/ConstructionTenders.svg"
        );

        let translateYValue;

        if (scrollPosition === 0) {
          translateYValue = 0;
        } else if (scrollPosition < transitionPosition) {
          translateYValue = -200 + transparency * 100;
        } else {
          translateYValue = -100 + transparency * 100;
        }

        headerRef.current.style.backgroundColor = backgroundColor;
        headerRef.current.style.transform = `translateY(${translateYValue}%)`;

        const links = headerRef.current.querySelectorAll("a");
        links.forEach((link) => {
          link.style.color = textColor;
        });
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  });

  return (
    <Box
      ref={headerRef}
      component={"header"}
      sx={{
        display: "flex",
        flexDirection: { md: "row", xs: loggedIn ? "row" : "row-reverse" },
        justifyContent: { xs: "space-between", md: "unset" },
        alignItems: "center",
        width: "100%",
        py: 2,
        bgcolor: "rgba(255,255,255,0.1)",
        position: "fixed",
        transform: "translateY(0)",
        transition:
          "transform 0.3s ease-in-out, background-color 0.3s ease-in-out",
        zIndex: 9999,

        "& li": {
          p: 0,
        },

        "& a": {
          textDecoration: "none",
          color: "#fff",
          ml: 3,
        },

        "& li > div": {
          maxWidth: "fit-content",
          display: "flex",
          justifyContent: "center",
          borderRadius: 1.4,
        },

        "& li :hover": {
          color: "#fff",
          bgcolor: "#8731E8",
          borderRadius: 1.4,
        },

        "& li:last-child :hover": {
          color: "unset",
          bgcolor: "unset",
          "& svg": { color: "#000" },
        },

        "& a :hover ": {
          color: "#fff !important",
        },

        "& li:last-child :hover > a": {
          color: "#000 !important",
        },

        "& a.active": {
          color: "#fff !important",
          bgcolor: "#8731E8",
          borderRadius: 1.4,
        },
      }}
    >
      <HamBurgerMenu>
        {!loggedIn && (
          <>
            <Divider />

            <List>
              <ListItem disablePadding>
                <NavLink to={"/signIn"}>
                  <ListItemButton>
                    <ListItemText primary={"Sign In"} />
                  </ListItemButton>
                </NavLink>
              </ListItem>

              <ListItem disablePadding>
                <NavLink to={"/register"}>
                  <ListItemButton>
                    <ListItemText primary={"Register"} />
                  </ListItemButton>
                </NavLink>
              </ListItem>
            </List>
          </>
        )}
      </HamBurgerMenu>

      <NavLink to={"/"} style={{ backgroundColor: "transparent" }}>
        <Card
          sx={{
            boxShadow: "none",
            width: "7rem",
            mx: 3,
            bgcolor: "transparent",
          }}
        >
          <CardMedia
            component={"img"}
            src={imageSrc}
            sx={{ width: "100%" }}
            alt=""
          />
        </Card>
      </NavLink>

      <List
        sx={{
          display: { md: "flex", xs: "none" },
          justifyContent: { md: "space-between", xs: "unset" },
          mr: { md: 3, xs: loggedIn ? 3 : 0 },

          width: { md: "100%", xs: "auto" },
        }}
      >
        <ListItem sx={{ display: "flex" }}>
          <NavLink to={"/"}>
            <ListItemButton>Home</ListItemButton>
          </NavLink>

          <NavLink to={"/about"}>
            <ListItemButton>About Us</ListItemButton>
          </NavLink>

          {/* <NavLink to={"/services"}>
              <ListItemButton>Services</ListItemButton>
            </NavLink> */}

          <NavLink to={"/contact"}>
            <ListItemButton>Contact</ListItemButton>
          </NavLink>
        </ListItem>

        <ListItem
          sx={{
            display: { md: "flex", xs: "none" },
            justifyContent: "right",
            "& a :hover ": {
              color: "#000 !important",
            },
          }}
        >
          {loggedIn ? (
            <ProfileMenuButton transparency={transparency} />
          ) : (
            <>
              <NavLink
                to={"/signIn"}
                style={{
                  marginLeft: 0,
                }}
              >
                <ListItemButton
                  sx={{
                    borderLeft: "solid 1px #fff",
                    borderRight: "solid 1px #fff",
                    borderRadius: "0 !important",
                    py: 0,
                    borderColor: transparency === 0 ? "#fff" : "#707070",
                  }}
                >
                  Sign In
                </ListItemButton>
              </NavLink>

              <NavLink to={"/register"} style={{ marginLeft: 0 }}>
                <ListItemButton
                  sx={{
                    borderRight: "solid 1px #fff",
                    ml: "0 !important",
                    borderRadius: "0 !important",
                    py: 0,
                    borderColor: transparency === 0 ? "#fff" : "#707070",
                  }}
                >
                  Register
                </ListItemButton>
              </NavLink>
            </>
          )}
        </ListItem>
      </List>
      <Box
        sx={{
          display: { xs: loggedIn ? "flex" : "none", md: "none" },
          justifyContent: "right",
        }}
      >
        <ProfileMenuButton transparency={transparency} />
      </Box>
    </Box>
  );
}
