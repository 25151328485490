import React from "react";
import Poster from "../OtherComponents/Poster";
import ContactPageContent from "../OtherComponents/ContactPageContent";

export default function Contact() {
  return (
    <>
      <Poster title="Contact Us" />

      <ContactPageContent />
    </>
  );
}
