import React, { useEffect, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
} from "@mui/material";
import { ArrowDownward } from "@mui/icons-material";
import { useCTContext } from "../../Context/CTContext";

export default function AdvanceTenderFilters({ advanceFilterData }) {
  const searchedByIndustry = null;
  const searchedByState = null;
  const searchedByCity = null;
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedState, setSelectedState] = useState(
    searchedByState ? searchedByState.toLowerCase() : ""
  );
  const [selectedCity, setSelectedCity] = useState(
    searchedByCity ? searchedByCity.toLowerCase() : ""
  );
  const [industry, setIndustry] = useState(
    searchedByIndustry ? searchedByIndustry : ""
  );

  const [wordSearch, setWordSearch] = useState("");
  const [productID, setProductID] = useState();
  const [companyName, setCompanyName] = useState("");
  const [companyIndustry, setCompanyIndustry] = useState("");
  const [tenderValue, setTenderValue] = useState("");
  const [type, setType] = useState("");
  const [tenderHostFromDate, setTenderHostFromDate] = useState("");
  const [tenderHostToDate, setTenderHostToDate] = useState("");
  const [tenderClosingFromDate, setTenderClosingFromDate] = useState("");
  const [tenderClosingToDate, setTenderClosingToDate] = useState("");

  const {
    countryNames,
    stateNames,
    cityNames,
    fetchCountries,
    fetchStates,
    fetchCities,
  } = useCTContext();

  useEffect(() => {
    fetchCountries();
    fetchStates(selectedCountry);

    if (searchedByCity !== "") {
      fetchCities(searchedByState);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (event, setStateFunction) => {
    const value = event.target.value;
    setStateFunction(value);

    if (setStateFunction === setSelectedCountry) {
      fetchStates("india" || value);
    }

    if (setStateFunction === setSelectedState) {
      fetchCities(value);
    }
  };

  const formatDate = (dateString) => {
    if (!dateString) return "";

    const date = new Date(dateString);
    const year = date.getFullYear();
    let month = (1 + date.getMonth()).toString().padStart(2, "0");
    let day = date.getDate().toString().padStart(2, "0");

    return `${year}-${month}-${day}`;
  };

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: 266,
        width: "fit-content",
      },
    },
  };

  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ArrowDownward />}
        aria-controls="panel1-content"
        id="panel1-header"
      >
        <Typography>Advance Filters</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Box
          component="form"
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: { sm: "space-between", xs: "center" },
              width: "100%",
              flexWrap: "wrap",
              mb: 3,

              "& > div": {
                width: { sm: "32%", xs: "100%" },
                mb: { xs: 2.3, md: 0 },

                ":last-of-type": {
                  mr: 0,
                },
              },
            }}
          >
            <FormControl color="secondary" size="small">
              <InputLabel>Country</InputLabel>
              <Select
                label={"Country"}
                value={selectedCountry}
                MenuProps={MenuProps}
                onChange={(e) => handleChange(e, setSelectedCountry)}
                sx={{ textTransform: "capitalize" }}
              >
                <MenuItem value="">All</MenuItem>
                {countryNames.map((country, i) => (
                  <MenuItem
                    key={i}
                    value={country.countryname}
                    sx={{ textTransform: "capitalize" }}
                  >
                    {country.countryname}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl color="secondary" size="small">
              <InputLabel>State</InputLabel>
              <Select
                label="State"
                value={selectedState}
                MenuProps={MenuProps}
                onChange={(e) => handleChange(e, setSelectedState)}
                sx={{ textTransform: "capitalize" }}
              >
                <MenuItem value="">All</MenuItem>
                <MenuItem value="Not Classified">Not Classified</MenuItem>
                <MenuItem value="Multi State">Multi State</MenuItem>
                {stateNames.map((state, i) => (
                  <MenuItem
                    key={i}
                    value={state}
                    sx={{ textTransform: "capitalize" }}
                  >
                    {state}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl color="secondary" size="small">
              <InputLabel>City</InputLabel>
              <Select
                label="City"
                value={selectedCity}
                MenuProps={MenuProps}
                onChange={(e) => handleChange(e, setSelectedCity)}
                sx={{ textTransform: "capitalize" }}
              >
                <MenuItem value="">All</MenuItem>
                <MenuItem value="Not Classified">Not Classified</MenuItem>
                <MenuItem value="Multi Location">Multi Location</MenuItem>
                {cityNames.map((city, i) => (
                  <MenuItem
                    key={i}
                    value={city}
                    sx={{ textTransform: "capitalize" }}
                  >
                    {city}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: { sm: "space-between", xs: "center" },
              width: "100%",
              flexWrap: "wrap",

              "& > div": {
                width: { md: "23%", sm: "48%", xs: "100%" },
                mr: { md: 2.3, xs: 0 },
                mb: { xs: 2.3, md: 0 },

                ":last-of-type": {
                  mr: 0,
                },
              },
            }}
          >
            <TextField
              label="Word Search"
              value={wordSearch}
              onChange={(e) => setWordSearch(e.target.value)}
              color="secondary"
              size="small"
            />

            <TextField
              label="Product ID"
              value={productID}
              onChange={(e) => setProductID(e.target.value)}
              color="secondary"
              size="small"
            />

            <TextField
              label="Company Name"
              value={companyName}
              onChange={(e) => setCompanyName(e.target.value)}
              color="secondary"
              size="small"
            />

            <TextField
              label="Tender Value"
              value={tenderValue}
              onChange={(e) => setTenderValue(e.target.value)}
              color="secondary"
              size="small"
            />
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              flexWrap: { xs: "wrap", sm: "nowrap" },
              my: 3,
              mt: { xs: 0, md: 3 },
              width: "100%",
            }}
          >
            <FormControl fullWidth color="secondary" size="small">
              <InputLabel>Company Industry</InputLabel>
              <Select
                label="Company Industry"
                value={companyIndustry}
                onChange={(e) => setCompanyIndustry(e.target.value)}
              >
                <MenuItem value="">All</MenuItem>
                <MenuItem value="Roads">Roads</MenuItem>
                <MenuItem value="Architect/Interior Designer">
                  Architect/Interior Designer
                </MenuItem>
                <MenuItem value="Bridges">Bridges</MenuItem>
                <MenuItem value="Building Material">Building Material</MenuItem>
                <MenuItem value="Canal/Irrigation Work">
                  Canal/Irrigation Work
                </MenuItem>
              </Select>
            </FormControl>

            <FormControl
              fullWidth
              sx={{ mx: { sm: 2.3, xs: 0 }, my: { xs: 2.3, sm: 0 } }}
              color="secondary"
              size="small"
            >
              <InputLabel>Industry</InputLabel>
              <Select
                label="Industry"
                value={industry}
                onChange={(e) => setIndustry(e.target.value)}
              >
                <MenuItem value="">All</MenuItem>
                <MenuItem value="Roads">Roads</MenuItem>
                <MenuItem value="Architect/Interior Designer">
                  Architect/Interior Designer
                </MenuItem>
                <MenuItem value="Bridges">Bridges</MenuItem>
                <MenuItem value="Building Material">Building Material</MenuItem>
                <MenuItem value="Canal/Irrigation Work">
                  Canal/Irrigation Work
                </MenuItem>
              </Select>
            </FormControl>

            <FormControl fullWidth color="secondary" size="small">
              <InputLabel>Tender Type</InputLabel>
              <Select
                label="Tender Type"
                value={type}
                onChange={(e) => setType(e.target.value)}
              >
                <MenuItem value="">All</MenuItem>
                <MenuItem value="Buy">Buy</MenuItem>
                <MenuItem value="Sell">Sell</MenuItem>
                <MenuItem value="Contract">Contract</MenuItem>
              </Select>
            </FormControl>
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: { md: "space-between", xs: "center" },
              flexWrap: "wrap",
              mb: 3,
              width: "100%",
              "& > div": {
                mx: { xs: 1.5, md: 0 },
                textAlign: { xs: "center", md: "left" },
                mb: 2.3,
                ":last-of-type": {
                  mb: 0,
                },
              },
            }}
          >
            <Box>
              <Typography variant="h6" sx={{ mb: 1.4, fontWeight: 600 }}>
                Tender Hosting Dates
              </Typography>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: { sm: "row", xs: "column" },
                }}
              >
                <Box>
                  <Typography>From</Typography>

                  <TextField
                    type="date"
                    value={formatDate(tenderHostFromDate)}
                    onChange={(e) => setTenderHostFromDate(e.target.value)}
                    size="small"
                  />
                </Box>

                <Box sx={{ ml: { sm: 1.4, xs: 0 }, mt: { sm: 0, xs: 1.4 } }}>
                  <Typography>To</Typography>

                  <TextField
                    type="date"
                    value={formatDate(tenderHostToDate)}
                    onChange={(e) => setTenderHostToDate(e.target.value)}
                    size="small"
                  />
                </Box>
              </Box>
            </Box>

            <Box>
              <Typography variant="h6" sx={{ mb: 1.4, fontWeight: 600 }}>
                Tender Closing Dates
              </Typography>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: { sm: "row", xs: "column" },
                }}
              >
                <Box>
                  <Typography>From</Typography>

                  <TextField
                    type="date"
                    value={formatDate(tenderClosingFromDate)}
                    onChange={(e) => setTenderClosingFromDate(e.target.value)}
                    size="small"
                  />
                </Box>

                <Box sx={{ ml: { sm: 1.4, xs: 0 }, mt: { sm: 0, xs: 1.4 } }}>
                  <Typography>To</Typography>

                  <TextField
                    type="date"
                    value={formatDate(tenderClosingToDate)}
                    onChange={(e) => setTenderClosingToDate(e.target.value)}
                    size="small"
                  />
                </Box>
              </Box>
            </Box>
          </Box>

          {/* Search Button */}
          <Button
            sx={{
              mt: { xs: 1.4 },
              px: 3.2,
              py: 1.4,
              textTransform: "capitalize",
              bgcolor: "#8731E8",
              fontWeight: 600,
              ":hover": {
                bgcolor: "#FFF",
                color: "#8731E8",
              },
            }}
            variant="contained"
            type="button"
            onClick={() => {
              advanceFilterData({
                selectedCity,
                selectedState,
                selectedCountry,
                wordSearch,
                productID,
                companyName,
                industry,
                companyIndustry,
                tenderValue,
                type,
                tenderHostFromDate,
                tenderHostToDate,
                tenderClosingFromDate,
                tenderClosingToDate,
              });
            }}
          >
            Advance Search
          </Button>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
}
