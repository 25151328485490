import React, { useEffect, useState } from "react";
import Poster from "../OtherComponents/Poster";
import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  Divider,
  TextField,
  Typography,
} from "@mui/material";
import { Call, Mail, Place } from "@mui/icons-material";
import { Link } from "react-router-dom";
import { useCTContext } from "../../Context/CTContext";
import { getUserData, updateProfile } from "../../API";

export default function EditProfile() {
  const {
    loginRequire,
    userID,
    userDetailId,
    domainId,
    name,
    setName,
    company,
    setCompany,
    designation,
    setDesignation,
    city,
    setCity,
    interestedIn,
    setInterestedIn,
    clientDomainName,
  } = useCTContext();

  const [userData, setUserData] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  loginRequire();

  useEffect(() => {
    const getUserDetails = async () => {
      try {
        const data = await getUserData(
          parseInt(userID),
          parseInt(userDetailId),
          parseInt(domainId)
        );

        setLoading(false);
        setUserData(data);
        setName(data.Name);
      } catch (error) {
        setError(error.message);
      }
    };

    getUserDetails();

    setCompany(userData.CompanyName);
    setDesignation(userData.Designation);
    setCity(userData.CityName);
    setInterestedIn(userData.InterestedIn);
  }, [
    userID,
    userDetailId,
    domainId,
    setName,
    setCity,
    setDesignation,
    setCompany,
    setInterestedIn,
    userData.Name,
    userData.CompanyName,
    userData.Designation,
    userData.CityName,
    userData.InterestedIn,
  ]);

  console.log(error);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await updateProfile(
        userID,
        userDetailId,
        domainId,
        name,
        company,
        designation,
        city,
        interestedIn,
        clientDomainName
      );

      if (response) {
        alert("Profile Updated Successfully.");
      } else {
        throw new Error("Failed to update profile.");
      }
    } catch (error) {
      alert("Error updating profile: " + error.message);
    }
  };

  return (
    <>
      <Poster title="Edit You Profile" />

      <Container maxWidth="sm" sx={{ my: 10, textTransform: "capitalize" }}>
        <Card>
          <CardHeader
            title={<Typography variant="h5"> Profile Details</Typography>}
            subheader={<Divider />}
          />

          <CardContent sx={{ pt: 0 }}>
            <Box sx={{ display: "flex" }}>
              <Avatar
                src="/images/icons/userDefault.svg"
                alt=""
                sx={{
                  boxShadow: "0 0 40px rgba(82, 85, 90, 0.1)",
                  height: 100,
                  width: 100,
                  mr: 3.2,
                }}
              />
              <Box>
                <Typography variant="h6" sx={{ fontWeight: 600 }}>
                  {userData.Name}
                </Typography>
                <Typography sx={{ fontWeight: 600, color: "#666" }}>
                  Designation:{" "}
                  {userData.Designation === "na" ? "N/A" : userData.Designation}
                </Typography>
              </Box>
            </Box>

            <Box
              sx={{
                mt: 3,

                "& a": {
                  textDecoration: "none",
                  color: "#666",

                  ":hover": {
                    color: "#963aff",
                  },
                },
                "& svg": {
                  color: "#963aff",
                  mr: 1,
                },
                ".MuiTypography-root": {
                  display: "flex",
                  mt: 3.2,
                },
              }}
            >
              <Link>
                <Typography>
                  <Place />
                  {userData.CityName || "N/A"}
                </Typography>
              </Link>

              <Link to={`tel:${userData.CountryISDCode} ${userData.MobileNo}`}>
                <Typography>
                  <Call />
                  {`${userData.CountryISDCode} ${userData.MobileNo}`}
                </Typography>
              </Link>

              <Link to={`mailto:${userData.EmailId}`}>
                <Typography sx={{ textTransform: "lowercase" }}>
                  <Mail />
                  {userData.EmailId}
                </Typography>
              </Link>
            </Box>

            <Divider sx={{ my: 2.3 }} />

            <Box>
              <Typography variant="h6" sx={{ mb: 2, fontWeight: 600 }}>
                Edit Profile
              </Typography>

              {loading ? (
                <Box>Loading...</Box>
              ) : (
                <Box
                  component={"form"}
                  onSubmit={handleSubmit}
                  sx={{
                    mt: 1,
                    ".MuiTextField-root": {
                      mb: 2.3,

                      "& .MuiInputBase-root": {
                        borderRadius: 0,
                      },
                    },
                  }}
                >
                  <TextField
                    type="email"
                    fullWidth
                    color="secondary"
                    placeholder="E-mail Address"
                    size="small"
                    value={userData.EmailId}
                  />

                  <TextField
                    fullWidth
                    color="secondary"
                    placeholder="Full Name"
                    size="small"
                    value={name}
                    // onChange={(e) => setName(e.target.value)}
                  />

                  <TextField
                    fullWidth
                    color="secondary"
                    placeholder="Company Name"
                    size="small"
                    value={company}
                    onChange={(e) => setCompany(e.target.value)}
                  />

                  <TextField
                    fullWidth
                    color="secondary"
                    placeholder="Designation"
                    size="small"
                    value={designation}
                    onChange={(e) => setDesignation(e.target.value)}
                  />

                  <TextField
                    fullWidth
                    color="secondary"
                    placeholder="City"
                    size="small"
                    value={city}
                    onChange={(e) => setCity(e.target.value)}
                  />

                  <TextField
                    fullWidth
                    color="secondary"
                    placeholder="Interested In"
                    size="small"
                    value={interestedIn}
                    onChange={(e) => setInterestedIn(e.target.value)}
                  />

                  <TextField
                    type="tel"
                    fullWidth
                    color="secondary"
                    placeholder="Phone Number"
                    size="small"
                    value={`${userData.CountryISDCode} ${userData.MobileNo}`}
                  />

                  <Button
                    type="submit"
                    variant="contained"
                    color="secondary"
                    fullWidth
                    sx={{
                      bgcolor: "#963aff",
                      py: 1.4,
                      borderRadius: 0,
                      mt: 2.3,
                    }}
                  >
                    Update Profile
                  </Button>
                </Box>
              )}
            </Box>
          </CardContent>
        </Card>
      </Container>
    </>
  );
}
