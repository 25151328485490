import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Typography,
} from "@mui/material";

import {
  CalendarMonth,
  Factory,
  Payments,
  Place,
  Spoke,
} from "@mui/icons-material";

import {
  NavLink,
  // useLocation
} from "react-router-dom";
import RightSideFlyer from "./RightSideFlyer";
import { getTenderList, getResultList } from "../../API";
import TenderSearch from "./TenderSearch";
import { useCTContext } from "../../Context/CTContext";
import HTMLRenderer from "./HTMLRenderer";

export default function TendersList() {
  const [tenders, setTenders] = useState([]);
  const [loading, setLoading] = useState(true);
  const { activeTab } = useCTContext();
  const [error, setError] = useState(null);
  // const location = useLocation();
  // const { dashboard } = location.state;
  // console.log(dashboard);

  const [filters, setFilters] = useState({
    searchKeyword: "",
    sortBy: "",
  });

  const [advanceFilters, setAdvanceFilters] = useState({
    selectedState: "",
    selectedCountry: "",
    selectedCity: "",
    wordSearch: "",
    productID: "",
    companyName: "",
    industry: "",
    companyIndustry: "",
    tenderValue: "",
    type: "",
    tenderHostFromDate: "",
    tenderHostToDate: "",
    tenderClosingFromDate: "",
    tenderClosingToDate: "",
  });

  const [page, setPage] = useState(0);

  const filterData = async (data) => {
    setFilters(data);
  };
  const advanceFilterData = async (data) => {
    setAdvanceFilters(data);
  };

  const loadMoreTenders = async () => {
    try {
      const newTenders = await getTenderList({
        keyword: filters.searchKeyword,
        fromData: page + 20,
      });

      setTenders((prevTenders) => [...prevTenders, ...newTenders]);
      setPage(page + 20);
      console.log(page);
    } catch (error) {
      setError(error.message);
    }
  };

  useEffect(() => {
    // const ATHFD = Date.parse(advanceFilters.tenderHostFromDate);
    const fetchTenders = async () => {
      try {
        const filteredData = await (activeTab === 0
          ? getTenderList({ keyword: filters.searchKeyword })
          : getResultList({ keyword: filters.searchKeyword }));

        const filteredTenders = filteredData.filter((tender) => {
          return (
            (filters.searchKeyword === "" ||
              tender.keywordname === null ||
              tender.keywordname
                .toLowerCase()
                .includes(filters.searchKeyword.toLowerCase()) ||
              tender.countryname === null ||
              tender.countryname
                .toLowerCase()
                .includes(filters.searchKeyword.toLowerCase()) ||
              tender.statename === null ||
              tender.statename
                .toLowerCase()
                .includes(filters.searchKeyword.toLowerCase()) ||
              tender.cityname === null ||
              tender.cityname
                .toLowerCase()
                .includes(filters.searchKeyword.toLowerCase()) ||
              tender.address === null ||
              tender.address
                .toLowerCase()
                .includes(filters.searchKeyword.toLowerCase()) ||
              tender.tendersbrief === null ||
              tender.tendersbrief
                .toLowerCase()
                .includes(filters.searchKeyword.toLowerCase()) ||
              tender.companyname === null ||
              tender.companyname
                .toLowerCase()
                .includes(filters.searchKeyword.toLowerCase()) ||
              tender.subindustryname === null ||
              tender.subindustryname
                .toLowerCase()
                .includes(filters.searchKeyword.toLowerCase()) ||
              tender.companysubindustryname === null ||
              tender.companysubindustryname
                .toLowerCase()
                .includes(filters.searchKeyword.toLowerCase()) ||
              tender.currcode === null ||
              tender.currcode
                .toLowerCase()
                .includes(filters.searchKeyword.toLowerCase()) ||
              tender.biddingtype === null ||
              tender.biddingtype
                .toLowerCase()
                .includes(filters.searchKeyword.toLowerCase()) ||
              tender.city === null ||
              tender.city
                .toLowerCase()
                .includes(filters.searchKeyword.toLowerCase()) ||
              tender.tendersbriefnew === null ||
              tender.tendersbriefnew
                .toLowerCase()
                .includes(filters.searchKeyword.toLowerCase()) ||
              tender.enctcno === null ||
              tender.enctcno
                .toLowerCase()
                .includes(filters.searchKeyword.toLowerCase()) ||
              tender.enctenderprocid === null ||
              tender.enctenderprocid
                .toLowerCase()
                .includes(filters.searchKeyword.toLowerCase()) ||
              tender.salespersonname === null ||
              tender.salespersonname
                .toLowerCase()
                .includes(filters.searchKeyword.toLowerCase()) ||
              tender.emailid === null ||
              tender.emailid
                .toLowerCase()
                .includes(filters.searchKeyword.toLowerCase())) &&
            // Advanced Filters
            //
            (advanceFilters.selectedCountry === "" ||
              tender.countryname.toLowerCase() ===
                advanceFilters.selectedCountry.toLowerCase()) &&
            (advanceFilters.selectedState === "" ||
              tender.statename.toLowerCase() ===
                advanceFilters.selectedState.toLowerCase()) &&
            (advanceFilters.selectedCity === "" ||
              tender.cityname
                .toLowerCase()
                .includes(advanceFilters.selectedCity.toLowerCase())) &&
            (advanceFilters.wordSearch === "" ||
              tender.keywordname
                .toLowerCase()
                .includes(advanceFilters.wordSearch.toLowerCase())) &&
            (advanceFilters.productID === "" ||
              tender.tenderprocid === advanceFilters.productID) &&
            (advanceFilters.companyName === "" ||
              tender.companyname
                .toLowerCase()
                .includes(advanceFilters.companyName.toLowerCase())) &&
            (advanceFilters.tenderValue === "" ||
              tender.tendervalue === advanceFilters.tenderValue) &&
            (advanceFilters.industry === "" ||
              tender.subindustryname
                .toLowerCase()
                .includes(advanceFilters.industry.toLowerCase())) &&
            (advanceFilters.companyIndustry === "" ||
              tender.companysubindustryname
                .toLowerCase()
                .includes(advanceFilters.companyIndustry.toLowerCase())) &&
            (advanceFilters.type === "" ||
              tender.type.toLowerCase() === advanceFilters.type.toLowerCase()) //&&
            // (filters.sortBy === "" ||
            //   tender.sortBy.toLowerCase() === filters.sortBy.toLowerCase()
            //   )
          );
        });
        setLoading(false);
        setTenders(filteredTenders);
      } catch (error) {
        setError(error.message);
      }
    };

    fetchTenders();
  }, [activeTab, filters, advanceFilters]);

  const visibleTenders = tenders;
  console.log("t: ", tenders);
  console.log("vt: ", visibleTenders);
  if (error) {
    console.log(error);
    return <Box>Error: {error}</Box>;
  }

  return (
    <Box>
      <TenderSearch
        resultCount={visibleTenders.length === 0 ? "0 " : visibleTenders.length}
        filterData={filterData}
        advanceFilterData={advanceFilterData}
      />

      <Box
        sx={{
          display: "flex",
          flexDirection: { md: "row", xs: "column" },
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            width: { md: "70%", xs: "100%" },
            display: "flex",
            flexDirection: "column",
            alignItems: loading || tenders.length === 0 ? "center" : "unset",
            // height: "1900px",
          }}
        >
          <Box sx={{ overflow: "auto", p: 1.4 }}>
            {loading ? (
              <Box>Loading...</Box>
            ) : tenders.length === 0 ? (
              <Typography variant="h5">No Such Data Found!</Typography>
            ) : (
              <>
                {visibleTenders.map((tender) => (
                  <Card
                    key={tender.tenderprocid || tender.resultid}
                    sx={{
                      py: 1.4,
                      bgcolor: "#FFF",
                      mb: 3,
                      width: "100%",
                      boxShadow: "0 0 14px 3px rgba(0, 0, 0, 0.15)",
                      textTransform: "capitalize",

                      ":hover": { boxShadow: "0 0 14px 1px #8731E8" },
                      "& a": {
                        textDecoration: "none",
                        color: "#000",
                        ":hover h5": { color: "#8731E8" },
                      },
                      "& p": { fontWeight: 600 },
                      "& span": {
                        color: "#555",
                        fontWeight: 500,
                        ml: 0.5,
                      },

                      ":last-of-type": { mb: 0 },
                    }}
                  >
                    <NavLink
                      to={`/${
                        activeTab === 0 ? tender.tenderprocid : tender.resultid
                      }/tenderDetails`}
                      state={{ tenderDetails: tender }}
                    >
                      <CardHeader
                        title={
                          <Typography
                            variant="h5"
                            sx={{ letterSpacing: 2, fontWeight: 600 }}
                          >
                            {activeTab === 1
                              ? tender.resultcompanyname
                              : tender.companyname}
                          </Typography>
                        }
                        subheader={
                          <Typography component="span">
                            {activeTab === 1 ? (
                              <>
                                TRID:
                                <span> {tender.resultid}</span>
                              </>
                            ) : (
                              <>
                                Tender Reference No.:
                                <span> {tender.tenderrefno}</span>
                              </>
                            )}
                          </Typography>
                        }
                      />

                      <CardContent>
                        {activeTab === 0 && (
                          <Typography
                            variant="body2"
                            sx={{
                              mb: 1,
                              alignItems: "flex-start !important",
                              "& span": {
                                width: {
                                  sm: "77%",
                                  xs: "100%",
                                },
                              },
                              maxHeight: 95,
                              overflow: "auto",
                            }}
                          >
                            <Factory
                              sx={{
                                color: "#8731E8",
                                fontSize: 18,
                                mr: 1,
                              }}
                            />
                            Keyword Name:
                            <span>{tender.keywordname || "NA"}</span>
                          </Typography>
                        )}

                        <Typography variant="body2" sx={{ mb: 1 }}>
                          <Place
                            sx={{ color: "#8731E8", fontSize: 18, mr: 1 }}
                          />
                          {tender.cityname}, {tender.statename},{" "}
                          {tender.countryname}
                        </Typography>

                        <Typography variant="body2" sx={{ mb: 1 }}>
                          <CalendarMonth
                            sx={{ color: "#8731E8", fontSize: 18, mr: 1 }}
                          />
                          {activeTab === 1 ? (
                            <>
                              Result Contract Award Date.:
                              <span>{tender.resultcontractawarddate}</span>
                            </>
                          ) : (
                            <>
                              Tender Date:<span>{tender.tenderdate}</span>
                            </>
                          )}
                        </Typography>

                        <Typography variant="body2" sx={{ mb: 2 }}>
                          <CalendarMonth
                            sx={{ color: "#8731E8", fontSize: 18, mr: 1 }}
                          />
                          {activeTab === 1 ? (
                            <>
                              Result Date.:
                              <span>{tender.resultdate}</span>
                            </>
                          ) : (
                            <>
                              Closing Date:<span>{tender.closingdate}</span>
                            </>
                          )}
                        </Typography>

                        <Box
                          sx={{
                            maxHeight: 140,
                            overflow: "auto",
                            mb: 1.4,
                          }}
                        >
                          <Typography variant="body2" component="span">
                            {activeTab === 1 ? (
                              <>
                                <HTMLRenderer content={tender.resultdetails} />
                              </>
                            ) : (
                              <>
                                <HTMLRenderer content={tender.tendersbrief} />
                              </>
                            )}
                          </Typography>
                        </Box>

                        {activeTab === 0 && (
                          <Typography variant="body2" sx={{ mb: 2 }}>
                            Address:
                            <span>
                              <HTMLRenderer content={tender.address || "NA"} />
                            </span>
                          </Typography>
                        )}

                        <Typography variant="body2">
                          <Payments
                            sx={{ color: "#8731E8", fontSize: 18, mr: 1 }}
                          />
                          {activeTab === 1 ? (
                            <>
                              Result Value:<span>{tender.resultvalue}</span>
                            </>
                          ) : (
                            <>
                              Tender Value:<span>{tender.tendervalue}</span>
                            </>
                          )}
                        </Typography>

                        <Typography
                          variant="body2"
                          sx={{
                            "& span": {
                              textTransform: "uppercase",
                              fontSize: 20,
                            },
                          }}
                        >
                          <Spoke
                            sx={{ color: "#8731E8", fontSize: 18, mr: 1 }}
                          />
                          {activeTab === 1 ? (
                            <>
                              Currency Symbol:
                              <span>
                                {tender.currsymbol ? tender.currsymbol : "NA"}
                              </span>
                            </>
                          ) : (
                            <>
                              Currency Code:
                              <span>
                                {tender.currcode ? tender.currcode : "NA"}
                              </span>
                            </>
                          )}
                        </Typography>
                      </CardContent>
                    </NavLink>
                  </Card>
                ))}
              </>
            )}
          </Box>

          <Box
            sx={{
              display: loading || visibleTenders.length === 0 ? "none" : "flex",
              justifyContent: "center",
              mt: 5,
            }}
          >
            <Button
              onClick={loadMoreTenders}
              color="secondary"
              variant="contained"
              sx={{
                width: { sm: "32%", xs: "50%" },
                minWidth: "fit-content",
                bgcolor: "#8731E8",
                py: 1,
              }}
            >
              View More
            </Button>
          </Box>
        </Box>

        <RightSideFlyer />
      </Box>
    </Box>
  );
}
