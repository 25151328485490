import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Layout from "./Components/Layout";
import Home from "./Components/Pages/Home";
import AboutUs from "./Components/Pages/AboutUs";
import Contact from "./Components/Pages/Contact";
// import Services from "./Components/Pages/Services";
import SignIn from "./Components/Pages/SignIn";
import Register from "./Components/Pages/Register";
import Tenders from "./Components/Pages/Tenders";
import TenderDetails from "./Components/Pages/TenderDetails";
import EditProfile from "./Components/Pages/EditProfile";
import { CTContextProvider } from "./Context/CTContext";

function App() {
  return (
    <BrowserRouter>
      <CTContextProvider>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Home />} />
            <Route path="about" element={<AboutUs />} />
            <Route path="contact" element={<Contact />} />
            {/* <Route path="services" element={<Services />} /> */}
            <Route path="signIn" element={<SignIn />} />
            <Route path="register" element={<Register />} />
            <Route path="editProfile" element={<EditProfile />} />

            <Route path="tenders" element={<Tenders />} />

            <Route path=":ref/tenderDetails" element={<TenderDetails />} />

            {/* <Route path="*" element={<ErrorPage />} /> */}
          </Route>
        </Routes>
      </CTContextProvider>
    </BrowserRouter>
  );
}

export default App;
