import { EventAvailable } from "@mui/icons-material";
import {
  Box,
  Card,
  CardHeader,
  CardMedia,
  Divider,
  Typography,
} from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";

export default function RightSideFlyer() {
  return (
    <Box
      sx={{
        mt: { md: 1.4, xs: 5 },
        width: { md: "28%", xs: "100%" },
        a: { textDecoration: "none", color: "#000" },
        display: "flex",
        flexDirection: { md: "column", sm: "row", xs: "column" },
        justifyContent: "space-between",
      }}
    >
      {ads.map((ad, index) => (
        <Card
          key={index}
          sx={{
            mt: { md: 5, sm: 0, xs: 3.2 },
            ml: { md: 0, sm: 3.2, xs: 0 },
            ":first-of-type": { mt: 0 },
            height: { md: "68vh", xs: "50vh" },
            width: "100%",
          }}
        >
          <Link>
            <CardHeader
              title={
                <Typography
                  sx={{
                    fontWeight: 600,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <EventAvailable sx={{ color: "#8731E8", mr: 1 }} /> {ad.title}
                </Typography>
              }
            />

            <Divider
              sx={{ height: "1px", bgcolor: "#666", mb: 3.2 }}
              variant="middle"
            />

            <CardMedia
              component={"img"}
              src={ad.imageSrc}
              sx={{ height: "100%", objectFit: "none" }}
            />
          </Link>
        </Card>
      ))}
    </Box>
  );
}

const ads = [
  { title: "Specials of the Day", imageSrc: "/images/SideBarAd1.jpg" },
  { title: "Specials of the Week", imageSrc: "/images/SideBarAd2.jpg" },
  { title: "Specials of the Month", imageSrc: "/images/SideBarAd3.jpg" },
];
