import React from "react";
import LatestTendersSlider from "../OtherComponents/LatestTendersSlider";
import TestimonialSlider from "../OtherComponents/TestimonialSlider";
import TendersByCitySection from "../OtherComponents/TendersByCitySection";
import Poster from "../OtherComponents/Poster";
import { useCTContext } from "../../Context/CTContext";

export default function Home() {
  const { handleSearched } = useCTContext();

  return (
    <>
      <Poster Home content handleSubmit={handleSearched} />

      <LatestTendersSlider />

      <TendersByCitySection />

      <TestimonialSlider />
    </>
  );
}
