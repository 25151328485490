import { Box, Button, Container, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import InPosterTabs from "./InPosterTabs";

export default function Poster(props) {
  const [searchKeyword, setSearchKeyword] = useState("");
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        boxShadow: "none",
        width: "100%",
        height: {
          sm: `${props.Home === true ? 950 : 284}px`,
          xs: `${props.Home === true ? 1220 : props.posterHeight || 235}px`,
        },
        position: "relative",
        top: 0,
        background: `linear-gradient(-36deg, rgba(69, 40, 220, ${
          props.Home === true ? 0.8 : 0.4
        }) 0%, rgba(135, 49, 232, ${
          props.Home === true ? 0.8 : 0.4
        }) 100%), url(${
          props.bgPath ? props.bgPath : "/images/HomePoster.jpg"
        }) no-repeat scroll center center/cover`,
        color: "#fff",
        "& span": { fontWeight: 400, opacity: 0.7 },
        textTransform: "capitalize",
      }}
    >
      <Box
        component={"section"}
        sx={{
          textAlign: "center",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: props.Home === true ? "center" : "flex-end",
          position: "absolute",
          width: "100%",
          height: "90%",
        }}
      >
        <Container maxWidth="md" sx={{ mt: props.Home ? 15 : 0 }}>
          <Typography
            variant="h5"
            sx={{
              fontSize: { sm: 50, xs: 41 },
              lineHeight: { sm: "66px", xs: 1.14 },
              fontFamily: "Montserrat, sans-serif",
              fontWeight: 700,

              letterSpacing: 0.5,
            }}
          >
            {props.title ? props.title : "Construction Tenders"}
          </Typography>

          <Typography
            sx={{
              fontSize: 16,
              px: 5,
              mt: props.content === true ? "1rem" : 0,
            }}
          >
            {props.content === true
              ? "Over 5,000 construction tenders, including Tender Corrigendum and BOQ, are regularly posted on a government website. These tenders cover a range of Product and Project categories. Daily updates on construction tenders and related project news are available online."
              : props.content}
          </Typography>
        </Container>

        {props.Home ? (
          <Container
            maxWidth="lg"
            sx={{
              mt: 5,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <InPosterTabs />

            <Box
              component={"form"}
              sx={{
                mt: 2,
                display: "flex",
                justifyContent: "center",
                alignItems: { xs: "center", sm: "unset" },
                flexDirection: { sm: "row", xs: "column" },
                width: { lg: "100%", sm: "90%", xs: "86%" },
                bgcolor: "#fff",
                px: 3,
                py: 4.1,
                borderRadius: 1.8,
                boxShadow: "0px 0px 0px 8px rgba(255, 255, 255, 0.2)",
              }}
              onSubmit={(e) => {
                e.preventDefault();

                props.handleSubmit(searchKeyword);
                navigate(`/tenders`);
                // navigate(`/tenders?search=${searchKeyword}`);
              }}
            >
              <TextField
                placeholder="Enter Your Keyword To Search For Tender."
                size="small"
                color="secondary"
                value={searchKeyword}
                onChange={(e) => setSearchKeyword(e.target.value)}
                sx={{
                  "& div": {
                    height: 50,
                    borderRadius: 1.8,
                  },
                }}
                fullWidth
              />
              <Button
                variant="contained"
                type="submit"
                color="secondary"
                sx={{
                  textTransform: "unset",
                  minWidth: 140,
                  width: { md: 180, xs: 140 },
                  fontSize: 14,
                  fontWeight: 600,
                  ml: { sm: 2, xs: 0 },
                  mt: { xs: 2, sm: 0 },
                  borderRadius: 1.8,
                  bgcolor: "rgba(135, 49, 232, 1)",
                }}
              >
                Search Now
              </Button>
            </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                flexWrap: "wrap",
                mt: 5,
                "& div": {
                  width: 115,
                  height: 100,
                  bgcolor: "rgba(255,255,255,.1)",
                  display: "flex",
                  alignItems: "center",
                  borderRadius: 1.4,
                  border: "1px solid rgba(255,255,255,.2)",
                  m: 1,
                },

                "& a": {
                  textDecoration: "none",
                  color: "#FFF",
                  height: "inherit",
                  display: "flex",
                  alignItems: "center",
                },
              }}
            >
              <Box>
                <Link to={"/tenders"}>View All Tenders</Link>
              </Box>
              <Box
                onClick={(e) => {
                  props.handleSubmit("Gujarat");
                }}
              >
                <Link to={"/tenders"}>Tenders By State</Link>
              </Box>
              <Box
                onClick={(e) => {
                  props.handleSubmit("Civil");
                }}
              >
                <Link to={"/tenders"}>Tenders By Industry</Link>
              </Box>
            </Box>
          </Container>
        ) : (
          ""
        )}
      </Box>
    </Box>
  );
}
