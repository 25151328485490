import React, { useEffect, useState } from "react";
import { CalendarMonth, CurrencyRupee, Place } from "@mui/icons-material";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Container,
  Typography,
} from "@mui/material";
import { useLocation } from "react-router-dom";

import { getResultDetails, getTenderDetails } from "../../API";
import { useCTContext } from "../../Context/CTContext";
import TenderOtherDetailsContent from "./TenderOtherDetailsContent";
import HTMLRenderer from "./HTMLRenderer";

export default function TenderDetailsPageContent() {
  const location = useLocation();
  const { tenderDetails } = location.state;
  const [details, setDetails] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { activeTab } = useCTContext();

  useEffect(() => {
    const getDetails = async () => {
      try {
        const data = await (activeTab === 0
          ? getTenderDetails(
              parseInt(tenderDetails.tcno),
              parseInt(tenderDetails.tenderprocid)
            )
          : getResultDetails(parseInt(tenderDetails.resultid)));
        setLoading(false);
        setDetails(data);
      } catch (error) {
        setError(error.message);
      }
    };

    getDetails();
  }, [tenderDetails, activeTab]);

  if (error) {
    return <Box>Error: {error}</Box>;
  }

  return (
    <>
      <Box variant="section">
        <Container
          sx={{
            my: 10,
            "& span": { fontWeight: 400, opacity: 0.7, ml: 0.5 },
            "& h6": {
              position: "relative",
              display: "flex",
              alignItems: "center",
              fontWeight: 600,
            },

            "& .MuiCard-root": {
              width: "100%",
              boxShadow: "0 0 10px 1px rgba(71, 85, 95, .1)",
              mr: 4,

              ":last-of-type": {
                mr: 0,
              },
              "& > div": {
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
              },
            },

            textTransform: "capitalize",
          }}
        >
          {(loading && <Box>Loading...</Box>) || (
            <>
              <Box>
                <Typography variant="h5" sx={{ fontWeight: 600, mb: 2.3 }}>
                  {details.CompanyName ? details.CompanyName : "NA"}
                </Typography>

                <Typography display="flex" alignItems={"center"}>
                  <Place sx={{ color: "#8731E8", fontSize: 18, mr: 0.2 }} />
                  {details.State ? details.State : "NA"},{" "}
                  {details.Country ? details.Country : "NA"}
                </Typography>

                <Typography>
                  {activeTab === 1 ? (
                    <>
                      TRID:
                      <Typography component="span">
                        <HTMLRenderer
                          content={details.TRId ? details.TRId : "NA"}
                        />
                      </Typography>
                    </>
                  ) : (
                    <>
                      Tender ID:
                      <Typography component="span">
                        <HTMLRenderer
                          content={
                            details.ProductTenderNo
                              ? details.ProductTenderNo
                              : "NA"
                          }
                        />
                      </Typography>
                    </>
                  )}
                </Typography>

                <Typography>
                  Tender No:
                  <Typography component="span">
                    {details.TenderNo ? details.TenderNo : "NA"}
                  </Typography>
                </Typography>

                {activeTab === 0 && (
                  <Typography>
                    Tender Type:
                    <Typography component="span">
                      <HTMLRenderer
                        content={details.TenderType ? details.TenderType : "NA"}
                      />
                    </Typography>
                  </Typography>
                )}

                <Typography>
                  Gem:
                  <Typography component="span">
                    {details.isgem ? details.isgem : 0}
                  </Typography>
                  {/* , ispq:
                  <Typography component="span">
                    {details.ispq ? details.ispq : 0}
                  </Typography>
                  , isboq:
                  <Typography component="span">
                    {details.isboq ? details.isboq : 0}
                  </Typography> */}
                </Typography>
              </Box>

              <Box
                sx={{
                  mt: 5,
                  "& h6": {
                    "::before": {
                      position: "absolute",
                      bottom: -7,
                      display: "block",
                      height: "4px",
                      bgcolor: "#8731E8",
                      content: '""',
                      width: "50px",
                      ml: 0.2,
                    },
                  },
                }}
              >
                <Card>
                  <CardHeader
                    title={
                      activeTab === 1 ? (
                        <Typography variant="h6">
                          Tender Result Description
                        </Typography>
                      ) : (
                        <Typography variant="h6">Tender Description</Typography>
                      )
                    }
                  />
                  <CardContent>
                    {activeTab === 1 && (
                      <Typography
                        sx={{
                          mb: 3,
                          fontWeight: 600,
                          "& span": {
                            color: "#8731E8",
                            fontSize: 20,
                            fontWeight: 600,
                          },
                        }}
                      >
                        Tender Won By:
                        <Typography component="span">
                          <HTMLRenderer
                            content={
                              details.TenderwonBy ? details.TenderwonBy : "NA"
                            }
                          />
                        </Typography>
                      </Typography>
                    )}

                    {activeTab === 1 ? (
                      <Typography component={"span"}>
                        <HTMLRenderer
                          content={
                            details.ResultDetails ? details.ResultDetails : "NA"
                          }
                        />
                      </Typography>
                    ) : (
                      <Typography component={"span"}>
                        <HTMLRenderer
                          content={
                            details.TenderDetail ? details.TenderDetail : "NA"
                          }
                        />
                      </Typography>
                    )}

                    <Typography sx={{ mt: 2, fontWeight: 600 }}>
                      {activeTab === 1 ? (
                        <>
                          Keyword Name:
                          <Typography component={"span"} sx={{ ml: 1 }}>
                            <HTMLRenderer
                              content={
                                details.KeywordName ? details.KeywordName : "NA"
                              }
                            />
                          </Typography>
                        </>
                      ) : (
                        <>
                          Sub-Industry Name:
                          <Typography component={"span"} sx={{ ml: 1 }}>
                            <HTMLRenderer
                              content={
                                details.SubIndustry ? details.SubIndustry : "NA"
                              }
                            />
                          </Typography>
                        </>
                      )}
                    </Typography>

                    <Typography sx={{ mt: 2, fontWeight: 600 }}>
                      {activeTab === 1 ? (
                        <>
                          Tender Product ID:
                          <Typography component={"span"} sx={{ ml: 1 }}>
                            <HTMLRenderer
                              content={
                                details.TenderProcId
                                  ? details.TenderProcId
                                  : "NA"
                              }
                            />
                          </Typography>
                        </>
                      ) : (
                        <>
                          Company Sub-Industry Name:
                          <Typography component={"span"} sx={{ ml: 1 }}>
                            <HTMLRenderer
                              content={
                                details.CompanyIndustry
                                  ? details.CompanyIndustry
                                  : "NA"
                              }
                            />
                          </Typography>
                        </>
                      )}
                    </Typography>

                    <Typography sx={{ mt: 0.5, fontWeight: 600 }}>
                      Address:
                      <Typography component={"span"} sx={{ ml: 1 }}>
                        <HTMLRenderer
                          content={
                            details.Address
                              ? details.Address
                              : details.CompanyName + ", " + details.City
                          }
                        />
                      </Typography>
                    </Typography>
                  </CardContent>
                </Card>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: { md: "row", xs: "column" },
                  justifyContent: { md: "space-between", xs: "center" },
                  mt: 4,
                  "& h6": {
                    "::before": {
                      position: "absolute",
                      bottom: -7,
                      display: "block",
                      height: "4px",
                      bgcolor: "#8731E8",
                      content: '""',
                      width: "50px",
                      ml: 0.2,
                    },
                  },
                  ".MuiCard-root": { mt: { xs: 2.3, md: 0 } },
                  ".MuiCardContent-root": { p: 5.5, pb: "3rem" },
                }}
              >
                <Card>
                  <CardHeader
                    title={
                      <Typography variant="h6">
                        <CurrencyRupee fontSize="small" sx={{ mr: 0.2 }} />
                        Key Values
                      </Typography>
                    }
                  />
                  <CardContent>
                    <Typography
                      sx={{
                        fontWeight: 600,
                        width: "100%",
                      }}
                    >
                      {activeTab === 1 ? (
                        <>
                          <Typography component={"span"} sx={{ mr: 1 }}>
                            Result Value:
                          </Typography>
                          <Typography component={"span"}>
                            {details.ResultValue ? details.ResultValue : "NA"}
                          </Typography>
                        </>
                      ) : (
                        <>
                          <Typography component={"span"} sx={{ mr: 1 }}>
                            Tender Value:
                          </Typography>
                          <Typography component={"span"}>
                            {details.Tendervalue
                              ? details.Tendervalue
                              : details.TenderValue || "NA"}
                          </Typography>
                        </>
                      )}
                    </Typography>

                    <Typography
                      sx={{
                        mt: 5,
                        fontWeight: 600,
                        width: "100%",
                      }}
                    >
                      {activeTab === 1 ? (
                        <>
                          <Typography component={"span"} sx={{ mr: 1 }}>
                            Currency Symbol:
                          </Typography>
                          <Typography component={"span"}>
                            {details.CurSymbol ? details.CurSymbol : "NA"}
                          </Typography>
                        </>
                      ) : (
                        <>
                          <Typography component={"span"} sx={{ mr: 1 }}>
                            Currency Code:
                          </Typography>
                          <Typography
                            component={"span"}
                            sx={{ textTransform: "uppercase" }}
                          >
                            {details.CurCode ? details.CurCode : "NA"}
                          </Typography>
                        </>
                      )}
                    </Typography>
                  </CardContent>
                </Card>

                <Card>
                  <CardHeader
                    title={
                      <Typography variant="h6">
                        <CalendarMonth fontSize="small" sx={{ mr: 0.2 }} />
                        Key Dates
                      </Typography>
                    }
                  />
                  <CardContent>
                    <Typography
                      sx={{
                        fontWeight: 600,
                        width: "100%",
                      }}
                    >
                      {activeTab === 1 ? (
                        <>
                          <Typography component={"span"} sx={{ mr: 1 }}>
                            Publication Date:
                          </Typography>
                          <Typography component={"span"}>
                            {details.PublicationDate
                              ? details.PublicationDate
                              : "NA"}
                          </Typography>
                        </>
                      ) : (
                        <>
                          <Typography component={"span"} sx={{ mr: 1 }}>
                            Tender Date:
                          </Typography>
                          <Typography component={"span"}>
                            {details.OpeningDate ? details.OpeningDate : "NA"}
                          </Typography>
                        </>
                      )}
                    </Typography>

                    <Typography
                      sx={{
                        mt: 5,
                        fontWeight: 600,
                        width: "100%",
                      }}
                    >
                      {activeTab === 1 ? (
                        <>
                          <Typography component={"span"} sx={{ mr: 1 }}>
                            Awarded Date:
                          </Typography>
                          <Typography component={"span"}>
                            {details.AwardedDate ? details.AwardedDate : "NA"}
                          </Typography>
                        </>
                      ) : (
                        <>
                          <Typography component={"span"} sx={{ mr: 1 }}>
                            Closing Date:
                          </Typography>
                          <Typography component={"span"}>
                            {details.ClosingDate ? details.ClosingDate : "NA"}
                          </Typography>
                        </>
                      )}
                    </Typography>
                  </CardContent>
                </Card>

                <Card>
                  <CardHeader
                    title={
                      <Typography variant="h6">
                        <Place fontSize="small" sx={{ mr: 0.2 }} />
                        Key Location
                      </Typography>
                    }
                  />
                  <CardContent>
                    <Typography
                      sx={{
                        fontWeight: 600,

                        width: "100%",
                      }}
                    >
                      <Typography component={"span"} sx={{ mr: 1 }}>
                        Location:
                      </Typography>
                      <Typography component={"span"}>
                        {details.State ? details.State : "NA"},{" "}
                        {details.Country ? details.Country : "NA"}
                      </Typography>
                    </Typography>

                    <Typography
                      sx={{
                        mt: 5,
                        fontWeight: 600,

                        width: "100%",
                      }}
                    >
                      <Typography component={"span"} sx={{ mr: 1 }}>
                        Address:
                      </Typography>
                      <Typography component={"span"}>
                        {details.City ? details.City : "NA"}
                      </Typography>
                    </Typography>
                  </CardContent>
                </Card>
              </Box>

              {
                <TenderOtherDetailsContent
                  Document={tenderDetails.isdocument}
                  PQ={tenderDetails.ispq}
                  BOQ={tenderDetails.isboq}
                  Corrigendum={tenderDetails.iscorrigendum}
                />
              }
            </>
          )}
        </Container>
      </Box>
    </>
  );
}
