import React, { useState } from "react";
import {
  Box,
  Drawer,
  Fab,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
} from "@mui/material";

import { Close, Menu } from "@mui/icons-material";
import { NavLink } from "react-router-dom";
export default function HamBurgerMenu({ children }) {
  const [open, setOpen] = useState(false);

  const toggleDrawer = (open) => () => {
    setOpen(open);
  };

  return (
    <Box
      sx={{
        display: { xs: "block", md: "none" },
        mx: { xs: 3, md: 0 },
      }}
    >
      <Fab
        onClick={toggleDrawer(!open)}
        sx={{
          "& svg": {
            color: "#9c27b0",
            height: 32,
            width: 32,
            transition: "transform 0.5s",
          },

          ":hover svg": {
            transform: "rotate(180deg)",
          },
        }}
      >
        {open ? <Close /> : <Menu />}
      </Fab>

      <Drawer
        anchor={"left"}
        open={open}
        onClose={toggleDrawer(false)}
        sx={{
          ".MuiDrawer-paper": {
            top: "140px",
            width: "10rem",
            height: "auto",
            borderRadius: "0 1.4rem 1.4rem 0",
          },
        }}
      >
        <Box
          role="presentation"
          onClick={toggleDrawer(false)}
          onKeyDown={toggleDrawer(false)}
          sx={{
            "& a": { textDecoration: "none", color: "#666" },
            "& li:hover a": {
              color: "#7b1fa2",
            },
            ".MuiListItemButton-root, ul, li, a": {
              width: "100%",
            },
          }}
        >
          <List>
            <ListItem disablePadding>
              <NavLink to={"/"}>
                <ListItemButton>
                  <ListItemText primary={"Home"} />
                </ListItemButton>
              </NavLink>
            </ListItem>

            <ListItem disablePadding>
              <NavLink to={"/about"}>
                <ListItemButton>
                  <ListItemText primary={"About Us"} />
                </ListItemButton>
              </NavLink>
            </ListItem>

            <ListItem disablePadding>
              <NavLink to={"/contact"}>
                <ListItemButton>
                  <ListItemText primary={"Contact"} />
                </ListItemButton>
              </NavLink>
            </ListItem>
          </List>

          {children}
        </Box>
      </Drawer>
    </Box>
  );
}
