import { Call, Mail, Place } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";

export default function ContactPageContent() {
  return (
    <Box
      component={"section"}
      sx={{
        bgcolor: "#FFF",
        py: 10,
        "& a": {
          textDecoration: "none",
          color: "unset",
          ":hover": {
            color: "#963aff",
          },
        },
      }}
    >
      <Container
        sx={{
          display: "flex",
          flexDirection: { sm: "row", xs: "column" },
          alignItems: "center",
        }}
      >
        <Box
          component={"form"}
          onSubmit={(e) => {
            e.preventDefault();
          }}
          sx={{
            width: { sm: "55%", xs: "100%" },
            textAlign: { xs: "center", sm: "left" },
          }}
        >
          <Typography variant="h6" sx={{ mb: 2.3, fontWeight: 600 }}>
            CONTACT US
          </Typography>

          <TextField
            color="secondary"
            placeholder="First Name"
            size="small"
            fullWidth
            sx={{ mb: 2 }}
          />

          <TextField
            color="secondary"
            placeholder="Last Name"
            size="small"
            fullWidth
            sx={{ mb: 2 }}
          />

          <TextField
            type="email"
            color="secondary"
            placeholder="Email"
            size="small"
            fullWidth
            sx={{ mb: 2 }}
          />

          <TextField
            multiline
            color="secondary"
            placeholder="Message"
            fullWidth
            sx={{ mb: 2 }}
            rows={5}
          />

          <Button
            variant="contained"
            color="secondary"
            type="submit"
            sx={{
              bgcolor: "#963aff",
              textTransform: "capitalize",
              py: 1.4,
              px: 2.8,
              fontSize: 16,
            }}
          >
            Send Message
          </Button>
        </Box>

        <Box
          sx={{
            ml: { md: 10, sm: 5, xs: 0 },
            mt: { xs: 5, sm: 0 },
            display: "flex",
            flexDirection: "column",

            width: { sm: "45%", xs: "100%" },
            wordBreak: "break-word",

            "& svg": {
              border: "1px solid",
              color: "#963aff",
              borderRadius: "50%",
              p: 1.4,
              fontSize: 32,
              mr: { md: 2, xs: 0 },
              transition: "border-color 0.5s ease, color 0.5s ease",
            },
            ".MuiCard-root": {
              mt: { xs: 2.3, md: 5 },
              display: "flex",
              alignItems: "center",
              ":hover svg": { borderColor: "transparent", color: "unset" },
              boxShadow: "none",
            },
            ".MuiCardContent-root": {
              "& p": {
                mt: 1,
              },
            },
          }}
        >
          <Card>
            <Place />
            <CardContent>
              <Link>
                <Typography>95 South Park Avenue,</Typography>
                <Typography>New York, NY 204 120</Typography>
              </Link>
            </CardContent>
          </Card>

          <Card>
            <Mail />
            <CardContent>
              <Link>
                <Typography>support@constructiontenders.com</Typography>
              </Link>

              <Link>
                <Typography>info@constructiontenders.com</Typography>
              </Link>
            </CardContent>
          </Card>

          <Card>
            <Call />
            <CardContent>
              <Link>
                <Typography>+ 1800 234 0200</Typography>
              </Link>

              <Link>
                <Typography>+ 1800 456 0300</Typography>
              </Link>
            </CardContent>
          </Card>
        </Box>
      </Container>
    </Box>
  );
}
