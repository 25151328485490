import React, { useEffect, useState } from "react";
import { Fab, useScrollTrigger, Zoom } from "@mui/material";
import { KeyboardDoubleArrowUp } from "@mui/icons-material";

export default function ScrollToTopButton() {
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 320,
  });
  const [showButton, setShowButton] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const shouldShowButton = window.scrollY > 730;
      setShowButton(shouldShowButton);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleClick = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <Zoom
      in={trigger || showButton}
      onClick={handleClick}
      role="presentation"
      style={{
        position: "fixed",
        zIndex: 2,
        bottom: 14,
        right: 14,
        display: "block",
        background: "#8731E8",
        transform: "scale(0.8)",
      }}
    >
      <Fab
        variant="contained"
        sx={{
          pt: 1,
          pb: 5,
          display: "flex",
          justifyContent: "center",
          borderRadius: 2,
          transition: "transform 0.5s",
          ":hover": {
            transform: "scale(0.7)",
          },
          "@keyframes pulse": {
            "50%": {
              transform: "scale(1)",
            },
          },
          ":hover ": {
            animation: "pulse 1s infinite",
          },
        }}
        size="small"
      >
        <KeyboardDoubleArrowUp sx={{ fontSize: 32, color: "#FFF" }} />
      </Fab>
    </Zoom>
  );
}
