import { Call, DoubleArrow, MailRounded, Place } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Container,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <Box
      component={"footer"}
      sx={{
        color: "#FFF",

        "& a": {
          textDecoration: "none",
          color: "#FFF",
          ":hover": { color: "#8731E8" },
          ":hover svg": { color: "#8731E8" },
          lineHeight: 2,
        },

        textAlign: { xs: "justify" },
      }}
    >
      <Box
        sx={{
          bgcolor: "rgba(18, 27, 34, 0.98)",
          py: 10,
          "& h6": {
            position: "relative",
            "::before": {
              position: "absolute",
              bottom: -7,
              display: "block",
              height: "4px",
              bgcolor: "#8731E8",
              content: '""',
              width: "50px",
              ml: 0.2,
            },
          },
        }}
      >
        <Container
          sx={{
            display: "flex",
            flexWrap: "wrap",
            flexDirection: { sm: "row", xs: "column" },
            justifyContent: "space-between",
            alignItems: { xs: "center", sm: "unset" },
          }}
        >
          <Card
            sx={{
              boxShadow: "none",
              bgcolor: "unset",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <CardMedia
              component={"img"}
              src="/images/Logo/ConstructionTenders_W.svg"
              sx={{
                width: { sm: 190, xs: "86%" },
              }}
            />

            <CardContent sx={{ p: 0, mt: 3 }}>
              <Typography
                sx={{ color: "#FFF", width: { sm: 266, xs: "100%" } }}
              >
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Cum
                incidunt architecto soluta laboriosam, perspiciatis, aspernatur
                officiis esse.
              </Typography>

              <Box sx={{ color: "#FFF", mt: 3 }}>
                <Link to={"https://www.google.com/maps/"}>
                  <Box>
                    <Place sx={{ color: "#8731E8", fontSize: 18, mr: 1.4 }} />
                    95 South Park Avenue, USA
                  </Box>
                </Link>
                <Link to={`tel:+456 875 369 208`}>
                  <Box>
                    <Call sx={{ color: "#8731E8", fontSize: 18, mr: 1.4 }} />
                    +456 875 369 208
                  </Box>
                </Link>

                <Link to={"mailto:support@constructiontenders.com"}>
                  <Box>
                    <MailRounded
                      sx={{ color: "#8731E8", fontSize: 18, mr: 1.4 }}
                    />
                    support@constructiontenders.com
                  </Box>
                </Link>
              </Box>
            </CardContent>
          </Card>

          <Box sx={{ mt: 10 }}>
            <Typography
              variant="h6"
              sx={{ mb: 4.1, fontWeight: 700, letterSpacing: 1 }}
            >
              Quick Links
            </Typography>

            <Link to="/">
              <Box>
                <DoubleArrow sx={{ fontSize: 18, mr: 1.4 }} />
                Home
              </Box>
            </Link>

            <Link to="/about">
              <Box>
                <DoubleArrow sx={{ fontSize: 18, mr: 1.4 }} />
                About Us
              </Box>
            </Link>

            <Link to="/contact">
              <Box>
                <DoubleArrow sx={{ fontSize: 18, mr: 1.4 }} />
                Contact
              </Box>
            </Link>
          </Box>

          <Box sx={{ mt: 10, width: { sm: 284, xs: "100%" } }}>
            <Typography
              variant="h6"
              sx={{ mb: 4.1, fontWeight: 700, letterSpacing: 1 }}
            >
              News Letters
            </Typography>

            <Typography>
              Sign Up for Our Newsletter to get Latest Updates and Offers.
              Subscribe to receive news in your inbox.
            </Typography>

            <Box
              component={"form"}
              onSubmit={(e) => {
                e.preventDefault();
              }}
              sx={{ mt: 3, width: "100%" }}
            >
              <TextField
                type="email"
                placeholder="Enter Your Email"
                sx={{
                  m: 0,
                  width: "98%",
                  color: "#FFF",
                  border: "solid #8731E8",
                  borderRadius: 1,
                  "& div": { color: "#FFF" },
                }}
                size="small"
                color="secondary"
              />

              <Button
                variant="contained"
                type="submit"
                sx={{
                  width: "100%",
                  bgcolor: "#8731E8",
                  color: "#FFF",
                  border: "5px solid #8731E8",
                  fontWeight: 600,
                  letterSpacing: 1,
                  mt: 2,
                  ":hover": {
                    bgcolor: "#FFF",
                    color: "#8731E8",
                    borderColor: "#8731E8",
                  },
                }}
              >
                Subscribe
              </Button>
            </Box>
          </Box>
        </Container>
      </Box>

      <Box
        sx={{
          py: 3,
          bgcolor: "#121B22",

          "& svg": {
            fill: "#FFF",
            ":hover": {
              fill: "#8731E8",
            },
          },
        }}
      >
        <Container
          sx={{
            display: "flex",
            flexDirection: { md: "row", xs: "column" },
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography sx={{ ml: { lg: 0, md: 5, xs: 0 } }}>
            AI Tiger. &copy; All Rights Reserved.
          </Typography>

          <Box
            sx={{
              width: 180,
              display: "flex",
              justifyContent: "space-between",
              mt: { md: 0, xs: 1.4 },
              mr: { lg: 0, md: 5, xs: 0 },
            }}
          >
            <Link to={"https://www.facebook.com/"}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                data-name="Layer 1"
                viewBox="0 0 24 24"
                id="facebook"
                width="20px"
                height="20px"
              >
                <path d="M15.12,5.32H17V2.14A26.11,26.11,0,0,0,14.26,2C11.54,2,9.68,3.66,9.68,6.7V9.32H6.61v3.56H9.68V22h3.68V12.88h3.06l.46-3.56H13.36V7.05C13.36,6,13.64,5.32,15.12,5.32Z" />
              </svg>
            </Link>

            <Link to={"https://twitter.com/?lang=en"}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                data-name="Layer 1"
                viewBox="0 0 22 22"
                id="twitter"
                width="20px"
                height="20px"
              >
                <path d="M22,5.8a8.49,8.49,0,0,1-2.36.64,4.13,4.13,0,0,0,1.81-2.27,8.21,8.21,0,0,1-2.61,1,4.1,4.1,0,0,0-7,3.74A11.64,11.64,0,0,1,3.39,4.62a4.16,4.16,0,0,0-.55,2.07A4.09,4.09,0,0,0,4.66,10.1,4.05,4.05,0,0,1,2.8,9.59v.05a4.1,4.1,0,0,0,3.3,4A3.93,3.93,0,0,1,5,13.81a4.9,4.9,0,0,1-.77-.07,4.11,4.11,0,0,0,3.83,2.84A8.22,8.22,0,0,1,3,18.34a7.93,7.93,0,0,1-1-.06,11.57,11.57,0,0,0,6.29,1.85A11.59,11.59,0,0,0,20,8.45c0-.17,0-.35,0-.53A8.43,8.43,0,0,0,22,5.8Z" />
              </svg>
            </Link>

            <Link to={"https://google.com/"}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 15 15"
                id="google-plus"
                width="23px"
                height="20px"
              >
                <path d="M16 7h-2V5h-1v2h-2v1h2v2h1V8h2zM5 9h2.829A3.006 3.006 0 0 1 5 11c-1.654 0-3-1.346-3-3s1.346-3 3-3c.717 0 1.407.257 1.943.724l1.314-1.508A4.955 4.955 0 0 0 5 3C2.243 3 0 5.243 0 8s2.243 5 5 5 5-2.243 5-5V7H5v2z" />
              </svg>
            </Link>

            <Link to={"https://www.youtube.com/"}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 32 32"
                id="youtube"
                width="20px"
                height="18px"
              >
                <path d="M27.683 15.938c-1.24-1.239-11.688-1.255-11.688-1.254 0-.001-10.446.016-11.689 1.253C3.063 17.178 3.06 23.3 3.06 23.336c0 .047.003 6.159 1.246 7.4C5.548 31.973 15.995 32 15.995 32s10.448-.027 11.688-1.264c1.248-1.244 1.258-7.4 1.258-7.4 0-.035-.014-6.157-1.258-7.398zm-16.656 3.608H9.268v8.894H7.566v-8.894H5.807v-1.512h5.22v1.512zM16 28.439h-1.507v-.843c-.281.312-.569.55-.873.711a1.816 1.816 0 0 1-.877.244c-.35 0-.61-.112-.79-.343-.174-.23-.264-.569-.264-1.028v-6.419h1.508v5.889c0 .183.032.313.093.395.067.082.17.122.31.122.111 0 .253-.054.422-.163.17-.108.324-.245.469-.411V20.76H16v7.679zm5.478-1.585c0 .543-.118.959-.355 1.25-.234.291-.579.433-1.027.433-.297 0-.559-.054-.789-.166a1.937 1.937 0 0 1-.645-.52v.589h-1.526V18.034h1.526v3.351c.205-.234.419-.413.647-.534.231-.123.462-.183.696-.183.479 0 .843.163 1.094.489.254.328.379.807.379 1.436v4.261zm5.234-2.066h-2.887v1.443c0 .405.049.685.147.842.102.157.271.233.512.233.25 0 .425-.066.524-.198.099-.132.151-.423.151-.876v-.35h1.552v.394c0 .788-.188 1.38-.568 1.781-.376.397-.942.594-1.693.594-.675 0-1.209-.209-1.597-.632-.387-.42-.585-1.001-.585-1.742V22.83c0-.664.215-1.209.642-1.628.426-.419.976-.629 1.653-.629.691 0 1.222.195 1.594.583.371.387.555.945.555 1.674v1.958z" />
                <path d="M24.499 21.916c-.24 0-.413.067-.519.211-.105.135-.156.367-.156.688v.775h1.334v-.775c0-.322-.054-.553-.16-.688-.099-.144-.27-.211-.499-.211zM19.294 21.889a.707.707 0 0 0-.32.074c-.105.05-.208.129-.311.234v4.784c.122.123.242.217.359.271a.85.85 0 0 0 .364.083c.185 0 .32-.054.407-.163.086-.107.132-.281.132-.528v-3.965c0-.259-.054-.457-.16-.59-.11-.134-.268-.2-.471-.2zM24.493 11.464h-1.7v-.934c-.31.343-.637.608-.977.786-.339.18-.667.27-.986.27-.396 0-.689-.126-.888-.378-.198-.252-.298-.629-.298-1.134V3.006h1.697V9.49c0 .2.039.345.11.434.069.09.187.135.345.135.124 0 .283-.06.474-.178.19-.118.366-.27.523-.456V3.006h1.7v8.458zM17.592 3.408c-.451-.41-1.028-.615-1.731-.615-.771 0-1.387.195-1.844.583-.459.387-.688.909-.687 1.565v4.383c0 .719.224 1.29.668 1.716.447.424 1.043.637 1.785.637.773 0 1.378-.206 1.821-.618.439-.413.659-.979.659-1.702V4.989c.001-.645-.224-1.172-.671-1.581zm-1.059 6.076c0 .225-.067.403-.203.534-.137.128-.318.193-.548.193-.226 0-.399-.062-.519-.189-.12-.129-.182-.307-.182-.538V4.877c0-.185.067-.335.195-.449a.745.745 0 0 1 .507-.17c.223 0 .405.056.543.17a.553.553 0 0 1 .208.449v4.607zM8.702 0H6.774l2.279 6.922v4.542h1.913V6.707L13.193 0h-1.946l-1.182 4.582h-.12z" />
              </svg>
            </Link>
          </Box>
        </Container>
      </Box>
    </Box>
  );
}
