import { Box, Button } from "@mui/material";
import React from "react";
import { useCTContext } from "../../Context/CTContext";

export default function InPosterTabs() {
  const { activeTab, handleTabChange } = useCTContext();

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        mb: 1.4,

        "& button": {
          borderRadius: 2,
          height: "50px",
          px: { sm: "35px", xs: "10px" },
          position: "relative",
          display: "block",
          letterSpacing: "0.4px",
          fontWeight: 600,
          transition: "all 0.4s ease",

          "&:hover, &:focus, &:active": {
            bgcolor: "#963aff",
            color: "#fff",
          },
          // "&::before": {},

          "&:focus::before, &:active::before": {
            bgcolor: "#963aff",
            content: '""',
            position: "absolute",
            bottom: "-10px",
            left: "41px",
            height: "20px",
            width: "20px",
            // bgcolor: "transparent",
            transform: "rotate(45deg)",
            transition: "all 0.9s ease",
          },
        },
        "& button:last-of-type": {
          ml: 2,
        },
      }}
    >
      <Button
        sx={{
          bgcolor: activeTab === 0 ? "#963aff" : "#fff",
          color: activeTab === 0 ? "#fff" : "#000",

          "&::before": {
            bgcolor: activeTab === 0 ? "#963aff" : "transparent",
          },
        }}
        onClick={() => handleTabChange(0)}
      >
        Tenders
      </Button>

      <Button
        sx={{
          bgcolor: activeTab === 1 ? "#963aff" : "#fff",
          color: activeTab === 1 ? "#fff" : "#000",

          "&::before": {
            bgcolor: activeTab === 1 ? "#963aff" : "transparent",
          },
        }}
        onClick={() => handleTabChange(1)}
      >
        Tender Results
      </Button>
    </Box>
  );
}
