import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Container,
  Typography,
} from "@mui/material";
import { NavLink } from "react-router-dom";
import { CalendarMonth, Place } from "@mui/icons-material";
import { getResultList, getTenderList } from "../../API";
import { useCTContext } from "../../Context/CTContext";
import HTMLRenderer from "./HTMLRenderer";

export default function LatestTendersSlider() {
  const [latestTender, setLatestTenders] = useState([]);
  const { activeTab } = useCTContext();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const getDetails = async () => {
      try {
        const tenders = await (activeTab === 0
          ? getTenderList({
              keyword: "contruction",
              sortBy: "desc",
              sortColumn: "closingdate",
            })
          : getResultList(
            {
              keyword: "contruction",
              sortBy: "desc",
              sortColumn: "resultdate",
            }
          ));

        setLoading(false);
        setLatestTenders(tenders.slice(0, 5));
      } catch (error) {
        setError(error.message);
      }
    };

    getDetails();
  }, [activeTab]);

  if (error) {
    return <Box>Error: {error}</Box>;
  }

  const settings = {
    dots: true,
    infinite: true,
    speed: 900,
    slidesToShow: 4,
    slidesToScroll: 4,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 771,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 601,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <Box component={"section"}>
      <Container
        maxWidth="xl"
        sx={{
          my: 10,

          "& .slick-dots ": { position: "unset", mt: 3 },

          "& .slick-dots li button:before": {
            fontSize: 0,
            opacity: 1,
            height: 12,
            width: 12,
            border: "3px #8731E8 solid",
            borderRadius: "50%",
          },

          "& .slick-dots li.slick-active button:before": {
            fontSize: 18,
            color: "#8731E8",
            border: "none",
          },

          "& .slick-slide > div": {
            display: "flex",
            justifyContent: "center",
          },

          "& .slick-prev:before, .slick-next:before": { display: "none" },
        }}
      >
        {loading ? (
          <Typography>Loading...</Typography>
        ) : (
          <>
            <Box
              sx={{
                textAlign: "center",
                mb: "40px",
              }}
            >
              <Typography
                variant="h5"
                sx={{
                  fontWeight: 700,
                  fontSize: 26,
                  color: "#000",
                  letterSpacing: 0.5,
                  "& span": { fontWeight: 600, color: "#333" },
                }}
              >
                {activeTab === 0 ? (
                  <>
                    <span>LATEST </span>TENDERS
                  </>
                ) : (
                  <>
                    <span>LATEST </span>TENDER RESULTS
                  </>
                )}
              </Typography>
              <Typography variant="body1" color={"#666"} mt={1.4}>
                {activeTab === 0
                  ? "Latest Added Tenders."
                  : "Latest Added Tender Results."}
              </Typography>
            </Box>
            {latestTender.length === 0 && "No Data Found."}
            <Container maxWidth="xl">
              <Slider {...settings}>
                {latestTender.map((tender, index) => (
                  <Card
                    key={index}
                    sx={{
                      borderRadius: 0,
                      boxShadow: "none",
                      maxWidth: { sm: "92%", xs: "100%" },
                      "& a": { textDecoration: "none", color: "inherit" },
                      ":hover .MuiTypography-h6": {
                        color: "#8731E8",
                      },
                      textTransform: "capitalize",
                    }}
                  >
                    <NavLink
                      to={`/${
                        activeTab === 0 ? tender.tcno : tender.resultid
                      }/tenderDetails`}
                      state={{ tenderDetails: tender }}
                    >
                      <CardHeader
                        title={
                          <Typography
                            variant="h6"
                            sx={{
                              fontWeight: 600,
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                              ":hover": {
                                whiteSpace: "balance",
                                // textOverflow: "unset",
                              },
                            }}
                          >
                            Sector:{" "}
                            {activeTab === 1
                              ? tender.resultcompanyname
                              : tender.companysubindustryname}
                          </Typography>
                        }
                        subheader={
                          <Typography
                            variant="subtitle2"
                            sx={{
                              fontSize: 14,
                              color: "#666",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <Place
                              sx={{ color: "#8731E8", fontSize: 18, mr: 0.5 }}
                            />
                            {tender.cityname}, {tender.countryname}
                          </Typography>
                        }
                        sx={{ ".MuiCardHeader-content": { maxWidth: "100%" } }}
                      />

                      <CardContent sx={{ fontSize: 14, pt: 0 }}>
                        <Typography
                          variant="body2"
                          color="text.secondary"
                          sx={{
                            minHeight: activeTab === 0 ? 140 : 86,
                            maxHeight: activeTab === 0 ? 140 : 86,
                            overflow: "auto",
                          }}
                        >
                          {activeTab === 1 ? (
                            <>
                              <HTMLRenderer content={tender.resultdetails} />
                            </>
                          ) : (
                            <>
                              <HTMLRenderer content={tender.tendersbrief} />
                            </>
                          )}
                        </Typography>

                        <Typography
                          variant="body1"
                          color="text.secondary"
                          sx={{
                            mt: 1.4,
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <CalendarMonth
                            sx={{ color: "#8731E8", fontSize: 18, mr: 0.5 }}
                          />
                          {activeTab === 1
                            ? tender.resultcontractawarddate
                            : tender.closingdate}
                        </Typography>
                      </CardContent>
                    </NavLink>
                  </Card>
                ))}
              </Slider>
            </Container>
          </>
        )}
      </Container>
    </Box>
  );
}

// const tenders = [
//   {
//     sector: "Civil Works Tender",
//     location: "Andaman And Nicobar Tenders",
//     description:
//       "Rewinding of 20HP submersible pump set, Dismantling of 7point5 hp pump, Dismantling of 5 hp pump, Dismantling of 15hp pump, Dismantling of 10hp pump, Rewinding of 5 hp pump, Dismantling of 3hp pump, Dismantling of 40hp pump - Rewinding of pump at Garacharma.",
//     timePosted: "Posted now",
//   },
//   {
//     sector: "Civil Works Tender",
//     location: "Gujarat Tenders",
//     description:
//       "Rewinding of 20HP submersible pump set, Dismantling of 7point5 hp pump, Dismantling of 5 hp pump, Dismantling of 15hp pump, Dismantling of 10hp pump, Rewinding of 5 hp pump, Dismantling of 3hp pump, Dismantling of 40hp pump - Rewinding of pump at Garacharma.",
//     timePosted: "Posted 2 minutes ago",
//   },
//   {
//     sector: "Civil Works Tender",
//     location: "Gujarat Tenders",
//     description:
//       "Rewinding of 20HP submersible pump set, Dismantling of 7point5 hp pump, Dismantling of 5 hp pump, Dismantling of 15hp pump, Dismantling of 10hp pump, Rewinding of 5 hp pump, Dismantling of 3hp pump, Dismantling of 40hp pump - Rewinding of pump at Garacharma.",
//     timePosted: "Posted 2 minutes ago",
//   },
//   {
//     sector: "Civil Works Tender",
//     location: "Gujarat Tenders",
//     description:
//       "Rewinding of 20HP submersible pump set, Dismantling of 7point5 hp pump, Dismantling of 5 hp pump, Dismantling of 15hp pump, Dismantling of 10hp pump, Rewinding of 5 hp pump, Dismantling of 3hp pump, Dismantling of 40hp pump - Rewinding of pump at Garacharma.",
//     timePosted: "Posted 2 minutes ago",
//   },
//   {
//     sector: "Civil Works Tender",
//     location: "Gujarat Tenders",
//     description:
//       "Rewinding of 20HP submersible pump set, Dismantling of 7point5 hp pump, Dismantling of 5 hp pump, Dismantling of 15hp pump, Dismantling of 10hp pump, Rewinding of 5 hp pump, Dismantling of 3hp pump, Dismantling of 40hp pump - Rewinding of pump at Garacharma.",
//     timePosted: "Posted 2 minutes ago",
//   },
// ];
