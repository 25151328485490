import React from "react";
import Slider from "react-slick";
import {
  Avatar,
  Box,
  Card,
  CardContent,
  Container,
  Rating,
  Typography,
} from "@mui/material";

export default function LatestTendersSlider() {
  const settings = {
    dots: true,
    infinite: true,
    speed: 900,
    slidesToShow: 3,
    slidesToScroll: 3,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 771,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 601,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <Box component={"section"} sx={{ bgcolor: "#f5f6ff", py: 10 }}>
      <Container
        maxWidth="lg"
        sx={{
          "& .slick-dots ": { position: "unset", mt: 3 },

          "& .slick-dots li button:before": {
            fontSize: 0,
            opacity: 1,
            height: 12,
            width: 12,
            border: "3px #8731E8 solid",
            borderRadius: "50%",
          },

          "& .slick-dots li.slick-active button:before": {
            fontSize: 18,
            color: "#8731E8",
            border: "none",
          },

          "& .slick-slide > div": {
            display: "flex",
            justifyContent: "center",
          },

          "& .slick-prev:before, .slick-next:before": { display: "none" },
        }}
      >
        <Box
          sx={{
            textAlign: "center",
            mb: "40px",
          }}
        >
          <Typography
            variant="h5"
            sx={{
              fontWeight: 700,
              fontSize: 26,
              color: "#000",
              letterSpacing: 0.5,
              "& span": { fontWeight: 600, color: "#333" },
            }}
          >
            <span>PEOPLE </span>SAYS
          </Typography>
          <Typography variant="body1" color={"#666"} mt={1.4}>
            There are many variations of lorem of Lorem Ipsum available for use
            a sit amet, consectetur debits adipisicing lacus.
          </Typography>
        </Box>

        <Container>
          <Slider {...settings}>
            {peopleSays.map((person, index) => (
              <Card
                key={index}
                sx={{
                  borderRadius: 0,
                  boxShadow: "none",
                  maxWidth: "95%",
                  ":hover": {
                    boxShadow: "0 0 20px 10x blcak",
                  },
                  textAlign: "center",
                }}
              >
                <CardContent
                  sx={{
                    fontSize: 14,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    color="text.secondary"
                    sx={{
                      lineHeight: 1.8,
                      mb: 3.6,
                    }}
                  >
                    {person.comment}
                  </Typography>

                  <Avatar
                    src={person.userImg}
                    alt=""
                    sx={{ width: 90, height: 90, mb: 1 }}
                  />

                  <Box>
                    <Typography
                      variant="h6"
                      sx={{ fontWeight: 600, fontSize: 16 }}
                    >
                      {person.name}
                    </Typography>
                    <Typography variant="body2" sx={{ color: "#333", mt: 0.5 }}>
                      {person.location}
                    </Typography>
                  </Box>

                  <Rating
                    name="half-rating"
                    value={person.rating}
                    precision={0.5}
                    readOnly
                    sx={{ fontSize: "1.1rem", mt: 1.4 }}
                  />
                </CardContent>
              </Card>
            ))}
          </Slider>
        </Container>
      </Container>
    </Box>
  );
}

const peopleSays = [
  {
    comment:
      "Lorem ipsum dolor sit amet, ligula magna at etiam aliquip venenatis. Vitae sit felis donec, suscipit tortor et sapien donec.",
    userImg: "/images/Testimonials/ts-1.jpg",
    name: "Mary Deshaw",
    location: "Chicago",
    rating: 4,
  },
  {
    comment:
      "Lorem ipsum dolor sit amet, ligula magna at etiam aliquip venenatis. Vitae sit felis donec, suscipit tortor et sapien donec.",
    userImg: "/images/Testimonials/ts-2.jpg",
    name: "Jhon Morris",
    location: "Los Angeles",
    rating: 5,
  },
  {
    comment:
      "Lorem ipsum dolor sit amet, ligula magna at etiam aliquip venenatis. Vitae sit felis donec, suscipit tortor et sapien donec.",
    userImg: "/images/Testimonials/ts-3.jpg",
    name: "Cristy Mayer",
    location: "San Francisco",
    rating: 4.5,
  },
  {
    comment:
      "Lorem ipsum dolor sit amet, ligula magna at etiam aliquip venenatis. Vitae sit felis donec, suscipit tortor et sapien donec.",
    userImg: "/images/Testimonials/ts-4.jpg",
    name: "Gary Steven",
    location: "Philadelphia",

    rating: 5,
  },
  {
    comment:
      "Lorem ipsum dolor sit amet, ligula magna at etiam aliquip venenatis. Vitae sit felis donec, suscipit tortor et sapien donec.",
    userImg: "/images/Testimonials/ts-5.jpg",
    name: "Lisa Smith",
    location: "New York",
    rating: 4,
  },
  {
    comment:
      "Lorem ipsum dolor sit amet, ligula magna at etiam aliquip venenatis. Vitae sit felis donec, suscipit tortor et sapien donec.",
    userImg: "/images/Testimonials/ts-6.jpg",
    name: "Ichiro Tasaka",
    location: "Houston",
    rating: 4.5,
  },
];
