import {
  Box,
  Card,
  CardHeader,
  CardMedia,
  Container,
  Typography,
} from "@mui/material";
import React from "react";

export default function OurTeam() {
  return (
    <Box component={"section"} sx={{ bgcolor: "#f5f6ff", py: 10 }}>
      <Container>
        <Box
          sx={{
            textAlign: "center",
            mb: "40px",
          }}
        >
          <Typography
            variant="h5"
            sx={{
              fontWeight: 700,
              fontSize: 26,
              color: "#000",
              letterSpacing: 0.5,
              "& span": { fontWeight: 600, color: "#333" },
            }}
          >
            <span>OUR </span>TEAM
          </Typography>
          <Typography variant="body1" color={"#666"} mt={1.4}>
            There are many variations of lorem of Lorem Ipsum available for use
            a sit amet, consectetur debits adipisicing lacus.
          </Typography>
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: { sm: "space-between", xs: "center" },
            flexWrap: "wrap",
          }}
        >
          {members.map((member, index) => {
            return (
              <Card
                key={index}
                sx={{
                  textAlign: "center",
                  borderRadius: 0,
                  ":last-of-type": { mr: 0 },
                  boxShadow: "1px 1px 30px #ccc",
                  width: { md: "23%", sm: "46%", xs: "86%" },
                  mt: { xs: 5, md: 0 },
                }}
              >
                <CardMedia component={"img"} src={member.imgSrc} />

                <CardHeader title={member.name} subheader={member.position} />
              </Card>
            );
          })}
        </Box>
      </Container>
    </Box>
  );
}

const members = [
  {
    imgSrc: "/images/Team/Member1.jpg",
    name: "Arling Tracy",
    position: "Acountant",
  },
  {
    imgSrc: "/images/Team/Member2.jpg",
    name: "Carls Jhons",
    position: "Financial Advisor",
  },
  {
    imgSrc: "/images/Team/Member3.jpg",
    name: "Katy Grace",
    position: "Photographer",
  },
  {
    imgSrc: "/images/Team/Member4.jpg",
    name: "Mark Web",
    position: "Founder & CEO",
  },
];
