import React, { useState } from "react";
import Poster from "../OtherComponents/Poster";
import {
  Box,
  Button,
  Checkbox,
  Container,
  FormControlLabel,
  // Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { useCTContext } from "../../Context/CTContext";
import { checkLogIn } from "../../API";

export default function SignIn() {
  const { loggedIn, login, setUserID, setUserDetailId, setDomainId, setToken } =
    useCTContext();
  const [email, setEmail] = useState("hemal.kabani@savex.in");
  const [password, setPassword] = useState("123**SA");
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    if (!email || !password) {
      setError("Please Provide Both Email and Password.");
      return;
    }
    try {
      const logInStatus = await checkLogIn(
        email.toString(),
        password.toString()
      );

      // alert(`${email}, ${password}, ${logInStatus.UserDetailId}, ${logInStatus.CustomerId}, ${logInStatus.DomainId}`);

      if (logInStatus.SuccessCode === 3) {
        login();

        setUserID(logInStatus.CustomerId);
        setUserDetailId(logInStatus.UserDetailId);
        setDomainId(logInStatus.DomainId);
        setToken(logInStatus.Token);
        navigate("/tenders");
      } else {
        setError("Invalid Email or Password.");
      }
    } catch (error) {
      setError("User Does'nt Exist.");
    }
  };

  if (loggedIn) {
    return <Link to="/" />;
  }

  return (
    <>
      <Poster title="Log In" />

      <Box
        component={"form"}
        onSubmit={handleLogin}
        sx={{
          my: 5,
          "& a": {
            textDecoration: "none",
            fontWeight: 600,
            color: "#963aff",
            ":hover": { color: "purple" },
          },
          color: "#666",
        }}
      >
        <Container
          sx={{
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            justifyContent: { md: "space-between", xs: "center" },
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              backgroundImage: "url('/images/Register_Login_BG.png')",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center center",
              backgroundSize: "contain",
              display: { sm: "block", xs: "none" },
              height: "75vh",
              width: "55%",
            }}
          />

          <Box
            sx={{
              width: "41%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <Box sx={{ mb: 2.3 }}>
              <Typography>Email</Typography>
              <TextField
                type="email"
                size="small"
                fullWidth
                color="secondary"
                required
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                autoComplete="userrname"
              />
            </Box>

            <Box sx={{ mb: 1.4 }}>
              <Typography>Password</Typography>
              <TextField
                type="password"
                size="small"
                fullWidth
                color="secondary"
                required
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                autoComplete="current-password"
              />
            </Box>

            {error && (
              <Typography color="error" sx={{ my: 2 }}>
                {error}
              </Typography>
            )}

            <Box
              sx={{
                mb: 2.3,
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <FormControlLabel
                sx={{
                  span: {
                    fontSize: { sm: 14, xs: 12 },
                    fontWeight: { xs: 600, sm: 400 },
                  },
                }}
                control={
                  <Checkbox color="secondary" sx={{ color: "#963aff" }} />
                }
                label="Remember Me"
              />

              <Typography
                sx={{
                  fontSize: { sm: 14, xs: 12 },
                  fontWeight: { xs: 600, sm: 400 },
                  textAlign: "right",
                  "& a": {
                    fontWeight: 100,
                    textDecoration: "none",
                    color: "#666",
                    ":hover": { color: "#963aff" },
                  },
                }}
              >
                <Link>Forgot Password ?</Link>
              </Typography>
            </Box>

            <Button
              type="submit"
              variant="contained"
              color="secondary"
              sx={{
                py: 1.8,
                px: 5,
                borderRadius: 9,
                textTransform: "capitalize",
                bgcolor: "#963aff",
                mb: 2,
              }}
            >
              Log In
            </Button>

            <Typography sx={{ fontSize: 14, textAlign: "center" }}>
              New To Construction Tenders ? <Link to="/register">Sign Up</Link>
            </Typography>
          </Box>

          {/* <Snackbar
            open={loggedIn}
            autoHideDuration={6000}
            onClose={loggedIn}
            message="Successfully Logged In!!"
          /> */}
        </Container>
      </Box>
    </>
  );
}
